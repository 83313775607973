
import React, { useState } from "react";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { useLocation,Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ListUsersOnday() {
  let query = useQuery();
  const [params, setParam] = useState('oncheck=1');
  const [userCode, setUserCode] = useState(parseInt(query.get("code")) ? parseInt(query.get("code")) : '');
  const [fullname, setFullname] = useState('');
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [dateCheck, setDateCheck] = useState(null)
  const [dateSelect, setDateSelect] = useState({datecheck:null})
  const [filterShow, setFilterShow] = useState(false)
  const [type, setType] = useState(null)
  const [partID, setPart_Id] = useState(null)
  const [company_id, setCompanyID] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (userCode && userCode !== '') _param += "&code=" + userCode
    if (fullname && fullname !== '') _param += "&fullname=" + fullname
    if (partID && partID !== '') _param += "&part_id=" + partID
    if (company_id && company_id !== '') _param += "&company_id=" + company_id
    if (type && type !== '') _param += "&type=" + type
    if (dateCheck && dateCheck !== '') _param += "&datecheck=" + dateCheck
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "code", label: "MSNV" },
    { name: "part", label: "Phòng ban" },
    { name: "company", label: "Công ty" },
    { name: "time_checkin", label: "Thời gian chấm vào" },
    { name: "checkin_status", label: "Trạng thái chấm vào" },
    { name: "time_checkout", label: "Thời gian chấm ra" },
    { name: "checkout_status", label: "Trạng thái chấm ra" },
    { name: "text_status", label: "Trạng thái công" },
    { name: "point", label: "Công" },
    { name: "datecheck", label: "Ngày chấm công" },
  ];

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const handleDate = (name, value) => {
 
    setDateCheck( moment(value).format('YYYY-MM-DD'))
    let newDate = Object.assign({}, dateSelect)
    newDate[name] = value
    setDateSelect(newDate)
  };

  return (<AkkhorLayout idrole="list-users_onday">
    <div className="breadcrumbs-area">
        <ul>
          <li>
              <Link to="/">Trang chủ</Link>
          </li>
          <li>
              Bảng chấm công chi tiết
          </li>
        </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={()=>setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-12 form-group">
              <label>Họ tên nhân viên </label>
              <input type="text" placeholder="Tìm theo tên nhân viên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-12 form-group">
              <label>Mã số nhân viên </label>
              <input type="text" placeholder="Tìm mã số nhân viên" value={userCode} onChange={(e) => setUserCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-12 form-group">
              <label>Phòng ban </label>
              <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} name='part_id'
                onChange={(e) => {
                  setPart_Id(e)
                }}></SearchBoxModal>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-12 form-group">
              <label>Công ty </label>
              <SearchBoxModal apiname={"company"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} name='company_id'
                onChange={(e) => {
                  setCompanyID(e)
                }}></SearchBoxModal>
            </div>
            <div className="col-md-6 col-12 form-group">
              <label>Khoảng thời gian</label>
              <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: new Date(), }}
                onCallback={handleCallback}
              >
                <div className='d-flex align-items-center'>
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setStartDate(e)}
                    value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                  />
                  <span className='fas fa-arrow-right mx-4' />
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setEndDate(e)}
                    value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                  />
                </div>
              </DateRangePicker>
            </div>
            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label>Ngày tháng cụ thể</label>
              <DateTimePicker onChange={(value) => { handleDate('datecheck', value) }} value={dateSelect.datecheck ? new Date(dateSelect.datecheck) : null} maxDate={new Date()} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
            </div>
            <div className="col-lg-3 col-12 form-group">
              <label>Trạng thái</label>
              <select className="form-select height32" onChange={(e)=>setType(e.target.value)}>
                  <option value="">Chọn trạng thái</option>
                  <option value="checkall">Chấm đủ</option>
                  <option value="notcheck">Không chấm công</option>
                  <option value="checkin_1">Vào đúng giờ</option>
                  <option value="checkout_1">Ra đúng giờ</option>
                  <option value="checkin_2">Chấm công muộn</option>
                  <option value="checkout_2">Về sớm</option>
                  <option value="checkin_0">Không chấm vào</option>
                  <option value="checkout_0">Không chấm ra</option>
              </select>
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="users_onday" idrole="list-users_onday" params={params} title="Tài khoản" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
