import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import AlertErrorForm from "../component/AlertErrorForm";

export default function FormLoaiHopDong() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams(); const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const [listParam, setListParam] = useState([])
    const [paramTitle, setParamTitle] = useState('')
    const [paramValue, setParamValue] = useState('')

    const defaultdetail = {
        title: '',
        filetype: '',
        urlfile: '',
        params: '',
        author_code: user.data.code,
        status: 1,
    }

    const listOfColumns = {
        fullname: 'Họ tên',
        code: 'Mã nhân viên',
        sex: 'Giới tính',
        companyname: 'Tên công ty',
        partname: 'Tên phòng ban',
        chucvu: 'Chức vụ',
        email: 'Email',
        mobile: 'Số điện thoại',
        cccd: 'Số CCCD/CMND/HC',
        birthday: 'Ngày sinh',
        cccd_date: 'Ngày cấp CCCD/CMND/HC',
        cccd_address: 'Nơi cấp CCCD/CMND/HC',
        address_tc: 'Địa chỉ thường chú',
        address: 'Địa chỉ hiện tại',
        bank_id: 'STK ngân hàng',
        bank_address: 'Chi nhánh ngân hàng',
        bank_city: 'Tỉnh/thành ngân hàng',
        bhxh_id: 'Mã bảo hiểm xã hội',
        mst_id: 'Mã số thuế',
        datejoining: 'Ngày gia nhập',
        datehdtv_start: 'Ngày bắt đầu HĐ thử việc',
        datehdtv_end: 'Ngày kết thúc HĐ thử việc',
        datehdld_start_1: 'Ngày bắt đầu HĐLĐ lần 1',
        datehdld_end_1: 'Ngày kết thúc HĐLĐ lần 1',
        datehdld_start_2: 'Ngày bắt đầu HĐLĐ lần 2',
        datehdld_end_2: 'Ngày kết thúc HĐLĐ lần 2',
        datehdld_start_3: 'Ngày bắt đầu HĐLĐ lần 3',
        datehdld_end_3: 'Ngày kết thúc HĐLĐ lần 3',
        hd_status: 'Tình trạng HĐ',
        qualification: 'Trình độ chuyên môn',
        majors: 'Chuyên ngành đào tạo',
        highschool: 'Trường đào tạo',
    }


    useEffect(function () {
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "hopdong_type/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        setDetail(res.data.data);
                        setListParam(JSON.parse(res.data.data.params))
                    } else console.log(res.data.message);
                }).catch((err) => console.log(err));
        }
    }, [user, id]);

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Vui lòng nhập tiêu đề').max(200, "Độ dài không quá 200 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            values.status = 0
            setLoading(true)
            let postData = []
            values.params = JSON.stringify(listParam)
            if (values.file) {
                let formData = new FormData();
                formData.append('file', values.file, values.file.name)
                formData.append('title', values.title)
                formData.append('filetype', values.filetype)
                formData.append('params', JSON.stringify(listParam))
                if (id > 0) formData.append('id', id)
                postData = formData
            } else {
                postData = values
            }
            if (id > 0) {
                if (values.file) {
                    Axios.post(URL_LIST_ALL + 'hopdong_type/', postData, user.config).then(res => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {
                            resetForm();
                            confirmAlert({
                                title: 'Thành công',
                                message: 'Bạn cập nhật mẫu hợp đồng thành công',
                                buttons: [{
                                    label: 'OK', onClick: () => {
                                        window.location.href = "/mau-hop-dong"
                                    },
                                }
                                ]
                            });
                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                } else {
                    Axios.put(URL_LIST_ALL + 'hopdong_type/' + id, postData, user.config).then(res => {
                        setLoading(false);
                        setSubmitting(false);
                        setRefresh(!refresh)
                        if (res.data.status === "success") {
                            resetForm();
                            confirmAlert({
                                title: 'Thành công',
                                message: 'Bạn cập nhật mẫu hợp đồng thành công',
                                buttons: [{
                                    label: 'OK', onClick: () => {
                                        window.location.href = "/mau-hop-dong"
                                    },
                                }
                                ]
                            });
                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                }
            } else {
                Axios.post(URL_LIST_ALL + 'hopdong_type', postData, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới mẫu hợp đồng thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const addParams = () => {
        if (paramTitle && paramValue && paramTitle !== '' && paramValue !== '') {
            let newList = listParam.slice()
            newList.push({ title: paramTitle, value: paramValue })
            setListParam(newList)
            setParamTitle('')
            setParamValue('')
        }
    }
    const deleteParam = (key) => {
        let newList = listParam.slice()
        newList.splice(key, 1)
        setListParam(newList)
    }
    const editParams = (i, type, value) => {
        let cloneParams = listParam.slice()
        cloneParams[i][type] = value
        setListParam(cloneParams)
    }

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/mau-hop-dong">Danh sách mẫu Văn bản, Hợp đồng</Link>
                    </li>
                    <li>{id ? 'Cập nhập mẫu Văn bản, Hợp đồng' : 'Thêm mới mẫu Văn bản, Hợp đồng'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Mẫu Văn bản, Hợp đồng</h3>
                        </div>
                    </div>

                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-4 col-12 form-group">
                                <label>Tên mẫu <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            <div className="col-sm-4 col-12 form-group">
                                <label>Loại file Văn bản, Hợp đồng <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("filetype")} >
                                    <option value="">Chọn loại file Văn bản, Hợp đồng</option>
                                    <option value="word">Văn bản (Word)</option>
                                    <option value="excel">Bảng tính (Excel)</option>
                                </select>
                                <AlertErrorForm formik={formik} name="filetype"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>

                            <div className="col-lg-8 col-12 form-group">
                                <label><b>Các mẫu văn bản, tài liệu kèm theo</b></label>
                                <input type="file" onChange={(e) => formik.setFieldValue('file', e.target.files[0])}></input>
                                {detail && detail.urlfile && <input type="text" className="form-control height32 mt-1" readOnly value={"https://apihr.bhsgroup.vn/" + detail.urlfile} />}
                            </div>
                            <div className="col-12"></div>
                            <div className="col-sm-12 col-12 form-group">
                                <label>Chọn các thông số, dữ liệu</label>
                                <div className="row">
                                    <div className="col-4 form-group">
                                        <input type="text" placeholder="Thông số trong văn bản" className="form-control height32" onChange={(e) => setParamTitle(e.target.value)} autoComplete="off" value={paramTitle} />
                                    </div>
                                    <div className="col-4 form-group">
                                        <select className="form-select height32" onChange={(e) => setParamValue(e.target.value)} value={paramValue}>
                                            <option value="">Chọn dữ liệu</option>
                                            <option value="other">Dữ liệu khác</option>
                                            {Object.keys(listOfColumns).map((k, i) => {
                                                return (<option value={k} key={i}>{listOfColumns[k]}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-4 form-group">
                                        <button type="button" className="btn btn-sm btn-info height32" onClick={() => addParams()}>Thêm</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-12 form-group">
                                <label>Thông số thay thế trong văn bản</label>
                                <div className="row">
                                    {listParam.map((k, i) => {
                                        return (<div className="col-3 mb-3" key={i}>
                                            <small>{listOfColumns[k.value]}</small><br />
                                            <div className="w-100 d-flex">
                                                <input type='text' className="form-control height32" value={k.title} onChange={(e) => editParams(i, 'title', e.target.value)} />
                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteParam(i)}>Xóa</button>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                                
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </AkkhorLayout>
    );
}
