
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function DanhSachLoaiHopDong() {


  let columns = [
    { name: "id", label: "ID"},
    { name: "title", label: "Tiêu đề" },
    { name: "filetype", label: "Loại file" },
    { name: "params", label: "Thông số" },
    { name: "author_code", label: "Người tạo" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
        <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              Danh sách mẫu hợp đồng
            </li>
        </ul>
    </div>
   
    <SRList name="hopdong_type" linkaction="mau-hop-dong" idrole="mau-hop-dong" params={''} title="" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
