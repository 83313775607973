import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import { useRef } from "react";
import Spinner from "./Spinner";
import { confirmAlert } from "react-confirm-alert";


export default function UploadButton(props) {
    const { onSellect, onlytype, multiple,filename,type } = props;
    const { user } = useSelector((state) => state.auth)
    const fileUpload = useRef(null);
    const [refreshFile, setRefreshFile] = useState(false)
    const [nameFile, setNameFile] = useState('')
    const [uploading, setUploading] = useState(false)
    const [listFile, setListFile] = useState([])

    const uploadProfilePic = (e) => {
        // setSelectedFile(e.target.files[0])
        var fileloads = e.target.files;
        var list_file = []
        if (fileloads) {
            const uploadPromises = Object.keys(fileloads).map(key => {
                var fileload = fileloads[key];
                let value = e.target.value;
                value = value.split('\\');
                setNameFile(nameFile, value.slice(-1)[0]);
                setUploading(true);
   
                let file_name = filename ? filename.replace('/', ".") : fileload.name
                console.log(file_name);
                if(onlytype && key >= 1) {
                    file_name = file_name.replace(onlytype, '_' +key + onlytype) 
                }
                
                let formData = new FormData();
                formData.append('file', fileload, file_name );
                formData.append('type', type);
                formData.append('name', 'file');
                formData.append('filename', file_name);
                formData.append('changename', filename ? 0 : 1);
                formData.append('folder', 0);

                return Axios.post(URL_LIST_ALL + 'fileupload', formData, user.config).then(res => {
                    if (res.data.status === "success") {
                        // Thêm kết quả vào list_file
                        list_file.push(res.data.message);
                        setListFile(prevFile => [...prevFile,res.data.message])
                    } else {
                        setUploading(false);
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                    setUploading(false);
                }).catch(err => {
                    console.log(err);
                    setUploading(false);
                });
            });

            // Sử dụng Promise.all để chờ tất cả các lời gọi Axios hoàn tất
            Promise.all(uploadPromises).then(() => {
                if (multiple) {
                    setUploading(false);
                }
                setRefreshFile(!refreshFile);
            });
        }

    };

    useEffect(() => {
        
        if(listFile && listFile.length > 0)onSellect(listFile)
    }, [listFile])

    const handleUpload = () => {
        fileUpload.current.click()
    };

    return (
        <div className="upload-modal height32 d-flex">
            <div className="input-group mr-2" onClick={() => handleUpload()}>
                <input type="file" ref={fileUpload} onChange={uploadProfilePic} className="d-none" accept={onlytype ? onlytype : '*'} multiple={multiple ? multiple : false} />
                <button className="btn btn-sm btn-info" type="button">Chọn file</button>
                <input type="text" className="form-control height32" placeholder="Tải lên..." readOnly value={nameFile} />
            </div>
            {uploading && <Spinner />}
        </div >);
}
//https://github.com/elrumordelaluz/reactour