
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function LayMatKhau() {
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const defaultdetail = {
        code: '', email: '',
    }

    const formik = useFormik({
        initialValues: defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            email: Yup.string().trim().nullable().required('Nhập email').max(150, "Độ dài không quá 150 ký tự"),
            code: Yup.string().trim().nullable().required('Nhập mã nhân viên').max(15, "Độ dài không quá 15 ký tự"),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.post(URL_LIST_ALL + 'quen-mat-khau', values, user.config).then(res => {
                setLoading(false);
                setSubmitting(false);
                if (res.data.status === "success") {
                    resetForm();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Mật mới đã được gửi tới email ' + values.email + '. Vui lòng kiểm tra email',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));

        },
    });

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Lấy mật khẩu</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Lấy mật khẩu</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 col-12 form-group">
                                <label>Email <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-6 col-12 form-group">
                                <label>Mã nhân viên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("code")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lấy mật khẩu</>}</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
