import React from "react";
import ReactApexChart from "react-apexcharts";

export default function HomeChartPie({ mdata }) {
  const pDangChuanBi = Math.round((mdata.dangchuanbi / mdata.tongcongviec) * 100);
  const pDangTienHanh = Math.round((mdata.dangtienhanh / mdata.tongcongviec) * 100);
  const pDaHoanThanh = Math.round((mdata.dahoanthanh / mdata.tongcongviec) * 100);
  const pChamDeadLine = Math.round((mdata.chamdeadline / mdata.tongcongviec) * 100);
  const pQuaHanDeadLine = Math.round((mdata.quahandeadline / mdata.tongcongviec) * 100);
  const state = {
    series: [mdata.dangchuanbi, mdata.dangtienhanh, mdata.dahoanthanh, mdata.chamdeadline, mdata.quahandeadline],
    options: {
      labels: ['Đang chuẩn bị', 'Đang tiến hành', 'Đã hoàn thành', 'Chậm deadline', 'Quá hạn deadline'],
      colors: ['#ffc107', '#0dcaf0', '#198754', '#dc3545', '#fd7e14'],
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  return (
    <>
      <div className="doughnut-chart-wrap">
        <ReactApexChart options={state.options} series={state.series} type="pie" width={360} />
      </div>
      <div className="row student-report px-4">
        <div className="col-6 col-sm-2 student-count">
          <div className="bg-total w-100">
            <span className="line line-total" width="100%"></span>
          </div>
          <h4 className="item-title text-total">Tổng công việc</h4>
          <div className="item-number">{mdata.tongcongviec}</div>
        </div>
        <div className="col-6 col-sm-2 student-count">
          <div className="bg-total w-100">
            <span className="line line-yellow" style={{ width: (pDangChuanBi <= 0 ? 2 : pDangChuanBi) + '%' }}></span>
          </div>
          <h4 className="item-title text-warning">Đang chuẩn bị</h4>
          <div className="item-number">{mdata.dangchuanbi}</div>
        </div>
        <div className="col-6 col-sm-2 student-count">
          <div className="bg-total w-100">
            <span className="line line-info" style={{ width: (pDangTienHanh <= 0 ? 2 : pDangTienHanh) + '%' }}></span>
          </div>
          <h4 className="item-title text-info">Đang Tiến hành</h4>
          <div className="item-number">{mdata.dangtienhanh}</div>
        </div>
        <div className="col-6 col-sm-2 student-count">
          <div className="bg-total w-100">
            <span className="line line-success" style={{ width: (pDaHoanThanh <= 0 ? 2 : pDaHoanThanh) + '%' }}></span>
          </div>
          <h4 className="item-title text-success">Đã đóng <small>(Hoàn thành)</small></h4>
          <div className="item-number">{mdata.dahoanthanh}</div>
        </div>
        <div className="col-6 col-sm-2 student-count">
          <div className="bg-total w-100">
            <span className="line line-danger" style={{ width: (pChamDeadLine <= 0 ? 2 : pChamDeadLine) + '%' }}></span>
          </div>
          <h4 className="item-title text-danger">Đã đóng <small>(chậm deadline)</small></h4>
          <div className="item-number">{mdata.chamdeadline}</div>
        </div>
        <div className="col-6 col-sm-2 student-count">
          <div className="bg-total w-100">
            <span className="line line-xdanger" style={{ width: (pQuaHanDeadLine <= 0 ? 2 : pQuaHanDeadLine) + '%' }}></span>
          </div>
          <h4 className="item-title text-xdanger">Quá hạn deadline</h4>
          <div className="item-number">{mdata.quahandeadline}</div>
        </div>
      </div>
    </>
  );
}
