import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";

export default function DashboardAdmin() {
  const { user } = useSelector((state) => state.auth);
  const info = user && user.data ? user.data : null;
  const [listnews, setListNews] = useState(null)
  const [songayphep, setSongayphep] = useState(null)
  const [songaybu, setSongaybu] = useState(null)

  useEffect(() => {
    axios.get(URL_LIST_ALL + "news?limit=10000&forusers=" + user.data.code, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          setListNews(res.data.data)
        } else console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [user]);// eslint-disable-line
  useEffect(function () {
    let uCode = user.data.code
    axios.get(URL_LIST_ALL + "cong_nhansu?user_code=" + uCode, user.config)
        .then(async (res) => {
            if (res.data.status === "success") {
                if (res.data.data && res.data.data[0]) {
                    const congnhasu = res.data.data[0]
                    setSongayphep(congnhasu.songayphep)
                    setSongaybu(congnhasu.songaybu)
                }
            } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
}, [user]);// eslint-disable-line

  return (<div className="home-dashboad">
    {info &&
      <div className="w-100 mt-1 form-info">
        <div className="row">
          <div className="col-12 col-md-6 bg-white-border pd-15 mb-2">
            <h3 className="w-100 text-center">Thông tin cá nhân</h3>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td><b>Họ tên: </b></td>
                  <td>{info.fullname}</td>
                </tr>
                <tr>
                  <td><b>Mã nhân viên: </b></td>
                  <td>{info.code}</td>
                </tr>
                <tr>
                  <td><b>Email: </b></td>
                  <td>{info.email}</td>
                </tr>
                <tr>
                  <td><b>Số điện thoại: </b></td>
                  <td>{info.mobile}</td>
                </tr>
                <tr>
                  <td><b>Bộ phận: </b></td>
                  <td>{info.part_title}</td>
                </tr>
                <tr>
                  <td><b>Chức vụ: </b></td>
                  <td>{info.chucvu}</td>
                </tr>
                <tr>
                  <td><b>Số ngày phép còn lại: </b></td>
                  <td>{songayphep}</td>
                </tr>
                <tr>
                  <td><b>Số ngày nghỉ bù còn lại: </b></td>
                  <td>{songaybu}</td>
                </tr>

              </tbody>
            </table>
            <div className="w-100 mt-2 justify-content-center">
              <Link to="/my-profile" className="btn btn-sm btn-success mr-2 mt-2">
                Thông tin chi tiết
              </Link>
              {/* <Link to="/lay-mat-khau" className="btn btn-sm btn-warning mr-2 mt-2 text-white">
                Lấy mật khẩu ứng dụng
              </Link>
              <Link to="/doi-mat-khau" className="btn btn-sm btn-info mr-2 mt-2 text-white">
                Đổi mật khẩu ứng dụng
              </Link> */}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="w-100 bg-white-border pd-15">
              <h3 className="w-100 text-center">Quy trình nội bộ</h3>
              <ul className="dashboard-list-new">
                {listnews && listnews.map((item, i) => {
                  return (
                    <li key={i}>
                      <a href={"/tin-tuc/" + item.id}><i className="	fas fa-angle-double-right"></i> {item.title}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    }
  </div>)
}