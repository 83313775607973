import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";

export default function FormLoaiVanThu() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams(); const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const defaultdetail = {
        param_type: 1,
        title: '',
        code: '',
        type_moderator: '',
        moderator: '',
        code_vb: '',
        status: 1,
    }


    useEffect(function () {
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "vanthu_param/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        let detail = res.data.data
                        setDetail(detail);
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [user, id]);


    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Vui lòng nhập tên loại văn thư').max(200, "Độ dài không quá 200 ký tự"),
            code: Yup.string().trim().nullable().required('Vui lòng nhập mã'),
            code_vb: Yup.string().trim().nullable().required('Vui lòng cấu trúc mã'),
            code_file: Yup.string().trim().nullable().required('Vui lòng cấu trúc file'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'vanthu_param/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật mẫu hợp đồng thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/edit-vanthu_param/" + id
                                },
                            }
                            ]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'vanthu_param', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới mẫu hợp đồng thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/add-vanthu_param"
                                },
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });


    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/vanthu_param">Danh sách loại văn thư</Link>
                    </li>
                    <li>{id ? 'Cập nhập loại văn thư' : 'Thêm mới loại văn thư'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Quản lý loại văn thư</h3>
                        </div>
                    </div>

                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-12 form-group">
                                <label>Tên loại văn thư <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-sm-6 col-12 form-group">
                                <label>Mã <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("code")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code"></AlertErrorForm>
                            </div>

                            {formik.values['type_check'] == 'canhan' &&
                                <div className="col-sm-3 col-12 form-group">
                                    <label>Chọn cá nhân</label>
                                    <SearchBoxModal apiname={"users"} placeholder="Chọn cá nhân" colvalue={'code'} colname={'fullname'} defaultValue={detail ? detail.checker_1 : user.data.checker_1} name='checker_1'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('checker_1', e)
                                        }}></SearchBoxModal>
                                </div>
                            }

                            {formik.values['type_check'] == 'phongban' &&
                                <div className="col-sm-3 col-12 form-group">
                                    <label>Chọn phòng ban</label>
                                    <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} defaultValue={detail ? detail.checker_1 : user.data.checker_1} name='checker_1'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('checker_1', e)
                                        }}></SearchBoxModal>
                                </div>
                            }

                            <div className="col-sm-6 col-12 form-group">
                                <label>Đối tượng cấp số</label>
                                <select className="form-select height32" {...formik.getFieldProps("type_moderator")}>
                                    <option value="">Chọn loại đối tượng cấp số</option>
                                    <option value="canhan">Cá nhân</option>
                                    <option value="phc">Phòng hành chính BHS</option>
                                    <option value="pns">Phòng nhân sự BHS</option>
                                    <option value="ppc">Phòng pháp chế BHS</option>
                                    <option value="phongban">Phòng ban khác</option>
                                    <option value="quanly">Quản lý trực tiếp</option>
                                    <option value="nguoixinso">Người xin số</option>
                                    <option value="vtgoc">Người đã duyệt văn thư gốc</option>
                                </select>
                            </div>

                            {formik.values['type_moderator'] == 'canhan' &&
                                <div className="col-sm-6 col-12 form-group">
                                    <label>Chọn cá nhân</label>
                                    <SearchBoxModal apiname={"users"} placeholder="Chọn cá nhân" colvalue={'code'} colname={'fullname'} defaultValue={detail ? detail.moderator : null} name='moderator_1'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('moderator', e)
                                        }} />
                                </div>
                            }

                            {formik.values['type_moderator'] == 'phongban' &&
                                <div className="col-sm-6 col-12 form-group">
                                    <label>Chọn phòng ban</label>
                                    <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} defaultValue={detail ? detail.moderator : null} name='moderator_1'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('moderator', e)
                                        }} />
                                </div>
                            }
                             <div className="col-12"></div>
                            <div className="col-sm-6 col-12 form-group">
                                <label>Áp dụng cho phòng ban cụ thể (Không chọn là áp dụng tất cả)</label>
                                <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} defaultValue={detail ? detail.part_use : user.data.part_use} name='part_use'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('part_use', e)
                                    }} />
                            </div>
                            <div className="col-sm-6 col-12 form-group">
                                <label>Áp dụng cho công ty cụ thể (Không chọn là áp dụng tất cả)</label>
                                <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" colvalue={'id'} colname={'title'} defaultValue={detail ? detail.company_use : user.data.company_use} name='company_use'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('company_use', e)
                                    }} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Cấu trúc mã <span className="text-danger">*</span></label>
                                <input type="text" placeholder="VD: [stt]/[year]/[type]-[mpp]" className="form-control height32" {...formik.getFieldProps("code_vb")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code_vb"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Cấu trúc file <span className="text-danger">*</span></label>
                                <input type="text" placeholder="VD: [stt]/[year]/[type]-[mpp][title]" className="form-control height32" {...formik.getFieldProps("code_file")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="code_file"></AlertErrorForm>
                            </div>
                            
                            <div className="col-12 form-group mg-t-8">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <span>Các cấu trúc mã và cấu trúc file được viêt tắt như sau: </span>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>[stt]</td>
                                                    <td>Số thứ tự văn bản</td>
                                                </tr>
                                                <tr>
                                                    <td>[year]</td>
                                                    <td>Năm tài chính</td>
                                                </tr>
                                                <tr>
                                                    <td>[type]</td>
                                                    <td>Loại văn bản</td>
                                                </tr>
                                                <tr>
                                                    <td>[type_p]</td>
                                                    <td>Loại văn bản đính kèm</td>
                                                </tr>
                                                <tr>
                                                    <td>[mpb]</td>
                                                    <td>Mã phòng ban hành</td>
                                                </tr>
                                                <tr>
                                                    <td>[mct]</td>
                                                    <td>Mã công ty</td>
                                                </tr>
                                                <tr>
                                                    <td>[msnv]</td>
                                                    <td>Mã nhân viên</td>
                                                </tr>
                                                <tr>
                                                    <td>[title]</td>
                                                    <td>Tên nội dung văn bản</td>
                                                </tr>
                                                <tr>
                                                    <td>[nk]</td>
                                                    <td>Người ký</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <p className="col-12">Ví dụ để được mã 45/2024/QyĐ-HC ta sẽ điền vào ô trên như sau: [stt]/[year]/[type]-[mpp][title]</p>
                                </div>
                            </div>
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </AkkhorLayout>
    );
}
