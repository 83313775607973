import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import { URL_API_BAO_CAO } from "../configs/api_bao_cao";
import Moment from "react-moment";
import { Formik } from "formik";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { checkRole } from "../model";
import Loading from "../component/Loading";
import SearchBoxModal from "../component/SearchBoxModal";
import ItemBaoCaoCong from "../component/ItemBaoCaoCong";
import HomeChartPie from "../home/ChartPie";

export default function BaoCaoCongViec() {
  const { user } = useSelector((state) => state.auth);
  const [params, setParam] = useState('');
  const [filterShow, setFilterShow] = useState(false)
  const [listCongViec, setListCongViec] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [timeType, setTimeType] = useState('week');
  const [listMonth, setListMonth] = useState([])
  const [listYear, setListYear] = useState([])
  const [fMonth, setFMonth] = useState(new Date().getMonth() + 1)
  const [fYear, setFYear] = useState(new Date().getFullYear())
  const [part_id, setPart_Id] = useState(user && user.data.part_id ? user.data.part_id : null);
  const [company_id, setCompany_Id] = useState(user && user.data.company_id ? user.data.company_id : null);
  const [tongQuan, setTongQuan] = useState(null)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [userCode, setUserCode] = useState(user && user.data.code)
  const [userFullname, setUserFullname] = useState(user && user.data.fullname)
  const [exportLink, setExportLink] = useState(null)
  const [viewType, setViewType] = useState('onlyme')

  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoading(true)
    let _param = '?'
    if (timeType && timeType !== '') _param += "&timeType=" + timeType
    if (timeType == 'month' || timeType == 'year') {
      if (fMonth && fMonth !== '' && timeType !== 'year') _param += "&month=" + fMonth
      if (fYear && fYear !== '') _param += "&year=" + fYear
    }
    if (timeType == 'time') {
      if (startDate && startDate !== '') _param += "&datestart=" + startDate
      if (endDate && endDate !== '') _param += "&dateend=" + endDate
    }
    if (viewType == 'mypart' || viewType == 'part') {
      if (part_id && part_id !== '') _param += "&part_id=" + part_id
    }
    if (viewType == 'mycompany' || viewType == 'company') {
      if (company_id && company_id !== '') _param += "&company_id=" + company_id
    }
    if (viewType == 'code') {
      if (userCode && userCode !== '') _param += "&usercode=" + userCode
      if (userFullname && userFullname !== '') _param += "&userFullname=" + userFullname
    }
    if (viewType && viewType !== '') _param += "&viewType=" + viewType
    setParam(_param);
  };

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  useEffect(function () {
    let _month = []
    for (let m = 1; m <= 12; m++) {
      _month.push(m)
    }
    setListMonth(_month)

    let _year = []
    let maxYear = new Date().getFullYear()
    for (let y = 2021; y <= maxYear + 1; y++) {
      _year.push(y)
    }
    setListYear(_year)
  }, []);

  useEffect(function () {
    setLoading(true)
    Axios.get(URL_API_BAO_CAO+"baocaocongviec" + params, user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          setListCongViec(res.data.data)
          setTongQuan(res.data.tongquan)
        } else {
          alert(res.data.message)
        }
        setLoading(false)
      }).catch((err) => {console.log(err);setLoading(false)});
  }, [user, refresh, params])

  const handleExport = () => {
    Axios.get(URL_API_BAO_CAO+"baocaocongviec" + params + (params ? '&export=true' : '?export=true'), user.config)
      .then(async (res) => {
        if (res.data.status === "success") {
          setExportLink("https://mybhsapi.bhsgroup.vn/" + res.data.data)
          alert("Đã xuất xong báo cáo, vui lòng chọn tải về")
        } else {
          alert(res.data.message)
        }
        setLoading(false)
      }).catch((err) => {console.log(err);setLoading(false)});
  }

  return (<AkkhorLayout idrole="baocaocongviec">
    {loading && (<Loading />)}
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>

        <li>Báo cáo công việc</li>
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Bộ lọc</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={() => setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>

        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>Tùy chọn</label>
              <select className="form-select height32" onChange={(e) => setTimeType(e.target.value)}>
                <option value="week">Báo cáo tuần này</option>
                <option value="month">Báo cáo theo tháng</option>
                <option value="year">Báo cáo theo năm</option>
                <option value="time">Báo cáo theo thời gian cụ thể</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>Đối tượng</label>
              <select className="form-select height32" onChange={(e) => setViewType(e.target.value)}>
                <option value="onlyme">Chỉ mình tôi</option>
                {checkRole(user, 'baocaocongviec', 'viewuser') && <option value="mymember">Các nhân viên của tôi</option>}
                {checkRole(user, 'baocaocongviec', 'viewpart') && <option value="parts">Chọn phòng ban</option>}
                {checkRole(user, 'baocaocongviec', 'viewmypart') && <option value="mypart">Phòng ban của tôi</option>}
                {checkRole(user, 'baocaocongviec', 'viewcompany') && <option value="company">Chọn theo công ty</option>}
                {checkRole(user, 'baocaocongviec', 'viewmycompany') && <option value="mycompany">Công ty của tôi</option>}
                {checkRole(user, 'baocaocongviec', 'viewall') && <option value="all">Tất cả</option>}
                {checkRole(user, 'baocaocongviec', 'viewall') && <option value="code">Mã nhân viên</option>}
              </select>
            </div>
            {viewType === 'code' &&
              <>
                <div className="col-12 col-sm-3 form-group">
                  <label>Mã nhân viên</label>
                  <input type="text" placeholder="Mã nhân viên" value={userCode} onChange={(e) => setUserCode(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
                <div className="col-12 col-sm-3 form-group">
                  <label>Họ và tên</label>
                  <input type="text" placeholder="Họ và tên" value={userFullname} onChange={(e) => setUserFullname(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
              </>
            }
            {(viewType === 'parts' ) &&
              <div className="col-12 col-sm-3 form-group">
                <label>Phòng ban </label>
                <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} name='part_id' defaultValue={part_id} disabled={viewType === 'mypart'}
                  onChange={(e) => {
                    setPart_Id(e)
                  }}></SearchBoxModal>
              </div>
            }
            {(viewType === 'company' || viewType === 'mycompany') &&
              <div className="col-12 col-sm-3 form-group">
                <label>Công ty </label>
                <SearchBoxModal apiname={"company"} placeholder="Chọn Công ty" colvalue={'id'} colname={'title'} name='company_id' defaultValue={company_id} disabled={viewType === 'mycompany'}
                  onChange={(e) => {
                    setCompany_Id(e)
                  }}></SearchBoxModal>
              </div>
            }
            {timeType !== 'week' && timeType !== 'time'
              && <>
                {timeType !== 'year' &&
                  <div className="col-sm-3 col-12 form-group">
                    <label>Chọn tháng</label>
                    <select className="form-select height32" onChange={(e) => { setFMonth(e.target.value) }} value={fMonth}>
                      {listMonth && listMonth.map((item, i) => {
                        return <option key={i} value={item}>Tháng {item}</option>
                      })}
                    </select>
                  </div>
                }
                <div className="col-lg-3 col-12 form-group">
                  <label>Chọn năm</label>
                  <select className="form-select height32" onChange={(e) => { setFYear(e.target.value) }} value={fYear}>
                    {listYear && listYear.map((item, i) => {
                      return <option key={i} value={item < 10 ? '0' + item.toString() : item}>{item}</option>
                    })}
                  </select>
                </div>
              </>
            }

            {timeType === 'time' &&
              <div className="col-md-6 col-12 form-group">
                <label>Khoảng thời gian</label>
                <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: new Date(), }}
                  onCallback={handleCallback}
                >
                  <div className='d-flex align-items-center'>
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Từ ngày'
                      readOnly={true}
                      onChange={(e) => setStartDate(e)}
                      value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                    />
                    <span className='fas fa-arrow-right mx-4' />
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Đến ngày'
                      readOnly={true}
                      onChange={(e) => setEndDate(e)}
                      value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                    />
                  </div>
                </DateRangePicker>
              </div>
            }

            <div className="col-12 d-flex justify-content-between">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
              <div className="right">
                <button type="button" onClick={handleExport} className="btn-sm btn-success">Xuất báo cáo</button>
                {exportLink && <a href={exportLink} target="_blank" className="btn btn-sm btn-info text-white ml-2"><span className="fas fa-cloud-download-alt"></span>Tải về</a>}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="w-100 listCV">
      <div className="table-cham-cong">
        <div className="wraper-table">
          <div className="table-header">
            <div className="table-tr">
              <div className="item-th item-td item-stt">STT</div>
              {/* <div className="item-th item-td item-stt">ID</div> */}
              <div className="item-th item-td item-title">Tên nhiệm vụ</div>
              <div className="item-th item-td item-desc">Mô tả</div>
              <div className="item-th item-td item-status">Tình trạng</div>
              <div className="item-th item-td item-datecreate">Ngày tạo</div>
              <div className="item-th item-td item-datestart">Ngày bắt đầu</div>
              <div className="item-th item-td item-deadline">Hạn chót</div>
              <div className="item-th item-td item-dateend">Ngày kết thúc</div>
              <div className="item-th item-td item-dateend">Chậm deadline</div>
              <div className="item-th item-td item-author">Người giao việc</div>
              <div className="item-th item-td item-trienkhai">Người triển khai</div>
              <div className="item-th item-td item-thamgia">Người tham gia</div>
              <div className="item-th item-td item-quansat">Người quan sát</div>
              <div className="item-th item-td item-kiennghi">Đề xuất kiến nghị</div>
              <div className="item-th item-td item-note">Ghi chú</div>
            </div>
          </div>
          <div className="table-body">
            {listCongViec &&
              Object.keys(listCongViec).map((key, i) => {
                let item = listCongViec[key]
                return (<React.Fragment key={item.ID}>
                  <div className="table-tr item-cv" key={item.ID}>
                    <div className="item-th item-td item-stt"><strong>{i + 1}</strong></div>
                    {/* <div className="item-th item-td item-stt"><strong>{item.ID}</strong></div> */}
                    <div className="item-th item-td item-title">
                      <strong>{item.TITLE}</strong>
                      {item.checklist && item.checklist.length > 0 &&
                        item.checklist.map((itCheck, ic) => {
                          return <div className={"w-100 itemCheckList d-flex justify-content-between " + (itCheck.TITLE.includes('#') ? 'mt-2 ' : 'pl-3')} key={ic}>
                            {itCheck.TITLE.includes('#') ? 
                            <>
                              <strong> {itCheck.TITLE} </strong>
                            </>
                            : 
                            <>
                              <span>{itCheck.SORT_INDEX + 1}. {itCheck.TITLE}</span> 
                              {itCheck.IS_COMPLETE === 'Y' ? <span className="fas fa-check-circle text-success"></span> : <span className="far fa-circle"></span>}
                            </>
                            
                            } 
                          </div>
                        })
                      }
                    </div>
                    <div className="item-th item-td item-desc" dangerouslySetInnerHTML={{ __html: item.DESCRIPTION_HTML }} />
                    <div className="item-th item-td item-status">
                      {item.STATUS == 1 && ""}
                      {item.STATUS == 2 && <span className="font-weight-bold bg-warning">Đang chuẩn bị</span>}
                      {item.STATUS == 3 && <span className="font-weight-bold bg-info">Đang tiến hành</span>}
                      {item.STATUS == 4 && <span className="font-weight-bold bg-success">Đã hoàn thành</span>}
                      {item.STATUS == 5 && (item.DEADLINE_TIME ? <span className="font-weight-bold bg-danger">Đã đóng <br /><small>(Chậm deadline)</small></span> : <span className="font-weight-bold bg-success">Đã đóng</span>)}
                      {item.STATUS == 6 && <span className="font-weight-bold bg-xdanger">Đã quá hạn</span>}
                    </div>
                    <div className="item-th item-td item-datecreate">
                      {item.CREATED_DATE && <Moment format="DD-MM-YYYY">{new Date(item.CREATED_DATE)}</Moment>}
                    </div>
                    <div className="item-th item-td item-datestart">
                      {item.DATE_START && <Moment format="DD-MM-YYYY">{new Date(item.DATE_START)}</Moment>}
                    </div>
                    <div className="item-th item-td item-deadline">
                      {item.DEADLINE && <Moment format="DD-MM-YYYY">{new Date(item.DEADLINE)}</Moment>}
                    </div>
                    <div className="item-th item-td item-dateend">
                      {item.CLOSED_DATE && <Moment format="DD-MM-YYYY">{new Date(item.CLOSED_DATE)}</Moment>}
                    </div>
                    <div className="item-th item-td item-dateend">
                      {item.DEADLINE_TIME ? item.DEADLINE_TIME : ''}
                    </div>
                    <div className="item-th item-td item-author">
                      {item.CREATED_BY_NAME}
                    </div>
                    <div className="item-th item-td item-trienkhai">
                      {item.RESPONSIBLE_NAME}
                    </div>
                    <div className="item-th item-td item-thamgia">
                      {item.LIST_MEMBER && item.LIST_MEMBER.map(function (itMem, iMem) {
                        return <p key={iMem}><span>{itMem}</span><br /></p>
                      })}
                    </div>
                    <div className="item-th item-td item-quansat">
                      {item.LIST_JOIN && item.LIST_JOIN.map(function (itJoin, iJoin) {
                        return <p key={iJoin}><span>{itJoin}</span><br /></p>
                      })}
                    </div>
                    <div className="item-th item-td item-kiennghi">
                      {item.PROPOSE ?
                        <div className="group-edit ">
                          <Formik
                            initialValues={{ param_value: item.PROPOSE }}
                            onSubmit={(values, { setSubmitting }) => {
                              let Data = { task_id: item.ID, param_key: 'PROPOSE', param_value: values.param_value }
                              Axios.put(URL_LIST_ALL + 'param_tasks/' + item.PROPOSE_ID, Data, user.config).then(res => {
                                setLoading(false);
                                setSubmitting(false);
                                if (res.data.status === "success") {
                                  confirmAlert({
                                    title: 'Thành công',
                                    message: 'Đề xuất kiến nghị thành công',
                                    buttons: [{ label: 'OK' }]
                                  });
                                  setRefresh(!refresh)
                                } else {
                                  confirmAlert({
                                    title: 'Lỗi',
                                    message: res.data.message,
                                    buttons: [{ label: 'OK' }]
                                  });
                                }
                              }).catch(err => console.log(err));
                            }}
                          >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                              <form className="d-flex w-100" onSubmit={handleSubmit}>
                                <div className="form-group">
                                  <textarea name="param_value" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                    {loading ? <Spinner /> : "Lưu"}
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                        :
                        <div className="group-add">
                          <Formik
                            initialValues={{ param_value: '' }}
                            onSubmit={(values, { setSubmitting }) => {
                              let Data = { task_id: item.ID, param_key: 'PROPOSE', param_value: values.param_value }
                              Axios.post(URL_LIST_ALL + 'param_tasks', Data, user.config).then(res => {
                                setLoading(false);
                                setSubmitting(false);
                                if (res.data.status === "success") {
                                  confirmAlert({
                                    title: 'Thành công',
                                    message: 'Đề xuất kiến nghị thành công',
                                    buttons: [{ label: 'OK' }]
                                  });
                                  setRefresh(!refresh)
                                } else {
                                  confirmAlert({
                                    title: 'Lỗi',
                                    message: res.data.message,
                                    buttons: [{ label: 'OK' }]
                                  });
                                }
                              }).catch(err => console.log(err));
                            }}
                          >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                              <form className="d-flex w-100" onSubmit={handleSubmit}>
                                <div className="form-group">
                                  <textarea name="param_value" className="form-control" placeholder="Nhập nội dung..." onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                    {loading ? <Spinner /> : "Lưu"}
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      }
                    </div>
                    <div className="item-th item-td item-note">
                      {item.NOTE ?
                        <div className="group-edit">
                          <Formik
                            initialValues={{ param_value: item.NOTE }}
                            onSubmit={(values, { setSubmitting }) => {
                              let Data = { task_id: item.ID, param_key: 'NOTE', param_value: values.param_value }
                              Axios.put(URL_LIST_ALL + 'param_tasks/' + item.NOTE_ID, Data, user.config).then(res => {
                                setLoading(false);
                                setSubmitting(false);
                                if (res.data.status === "success") {
                                  confirmAlert({
                                    title: 'Thành công',
                                    message: 'Đề xuất kiến nghị thành công',
                                    buttons: [{ label: 'OK' }]
                                  });
                                  setRefresh(!refresh)
                                } else {
                                  confirmAlert({
                                    title: 'Lỗi',
                                    message: res.data.message,
                                    buttons: [{ label: 'OK' }]
                                  });
                                }
                              }).catch(err => console.log(err));
                            }}
                          >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                              <form className="d-flex w-100" onSubmit={handleSubmit}>
                                <div className="form-group">
                                  <textarea name="param_value" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                    {loading ? <Spinner /> : "Lưu"}
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                        :
                        <div className="group-add">
                          <Formik
                            initialValues={{ param_value: '' }}
                            onSubmit={(values, { setSubmitting }) => {
                              let Data = { task_id: item.ID, param_key: 'NOTE', param_value: values.param_value }
                              Axios.post(URL_LIST_ALL + 'param_tasks', Data, user.config).then(res => {
                                setLoading(false);
                                setSubmitting(false);
                                if (res.data.status === "success") {
                                  confirmAlert({
                                    title: 'Thành công',
                                    message: 'Ghi chú nghị thành công',
                                    buttons: [{ label: 'OK' }]
                                  });
                                  setRefresh(!refresh)
                                } else {
                                  confirmAlert({
                                    title: 'Lỗi',
                                    message: res.data.message,
                                    buttons: [{ label: 'OK' }]
                                  });
                                }
                              }).catch(err => console.log(err));
                            }}
                          >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                              <form className="d-flex w-100" onSubmit={handleSubmit}>
                                <div className="form-group">
                                  <textarea name="param_value" className="form-control" placeholder="Nhập nội dung..." onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                    {loading ? <Spinner /> : "Lưu"}
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      }
                    </div>
                  </div>
                  {item.childrent && item.childrent.length > 0 && <ItemBaoCaoCong list={item.childrent} parent_counter={i + 1} setRefresh={setRefresh} refresh={refresh} loading={loading} setLoading={setLoading} />}
                </React.Fragment>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
    <div className="w-100 chart">
      <div className="card dashboard-card-three pd-b-20">
        <div className="card-body p-2">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>Tổng quan công việc {timeType === 'week' && 'tuần này'}{timeType === 'month' && 'tháng ' + fMonth + ' năm ' + fYear}{timeType === 'year' && 'năm ' + fYear}</h3>
            </div>
          </div>
          {tongQuan && <HomeChartPie mdata={tongQuan} />}
        </div>
      </div>
    </div>
  </AkkhorLayout>);
}
