import React from 'react';
import { useSelector } from 'react-redux';
import ScriptTag from 'react-script-tag';
import HeaderBar from '../component/HeaderBar';
import SiderBar from '../component/SiderBar';


export default function AkkhorLayout({ children, idrole, roleignone }) {
    const { user } = useSelector((state) => state.auth);
    const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')

    const checkRole = () => {
      
        const arr = idrole.split('.')
        let isrole = false;
        if (idrole === 'my-profile' || idrole === 'update-profile' || idrole === 'change-password') isrole = true
        else if (roleignone) isrole = true
        else if (arr.length === 2 && MyRole.hasOwnProperty(arr[0]) && MyRole[arr[0]].children.hasOwnProperty(arr[1]) && MyRole[arr[0]].children[arr[1]].checked === true) isrole = true
        else if (idrole && MyRole.hasOwnProperty(idrole) && MyRole[idrole].checked === true) isrole = true
        return isrole
    }

    return (<>
        {/* Preloader Start Here */}
        {/*<div id="preloader"></div>*/}
        {/* Preloader End Here */}
        
        <div id="wrapper" className="wrapper bg-ash">
            <HeaderBar></HeaderBar>
            
            {/* Page Area Start Here */}
            <div className="dashboard-page-one">
                <SiderBar></SiderBar>
                <div className="dashboard-content-one">
                    {checkRole() || idrole === "dashboard" ? <>{children}</> : <>
                        <div className="card mt-4 d-flex align-items-center justify-content-center">
                            <h3 className="text-danger text-center">Bạn không có quyền truy cập vào chức năng này!!!</h3>
                        </div>
                    </>}
                    {/* Footer Area Start Here */}
                    <footer className="footer-wrap-layout1">
                        <div className="copyright">© Copyrights 2022. All rights reserved. Designed by BHS Group</div>
                    </footer>
                    {/* Footer Area End Here */}
                </div>
            </div>
            {/* Page Area End Here */}
        </div>
        <ScriptTag type="text/javascript" src="/js/main.js" />
    </>
    );
}
