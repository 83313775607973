
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function DanhSachGuiMail() {
  const [params, setParam] = useState();
  const [email, setEmail] = useState('');
  const [filterShow, setFilterShow] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (email !== '') _param += "&mail_to=" + email
    setParam(_param);
  };
  let columns = [
    { name: "id", label: "ID" },
    { name: "mail_to", label: "Người nhận" },
    { name: "subject",label: "Tiêu đề"},
    { name: "body",label: "Nội dung"},
    { name: "logs",label: "Logs"},
    { name: "send_at",label: "Ngày gửi"},
    { name: "status",label: "Trạng thái"},
  ];


  return (<AkkhorLayout idrole="danh-sach-gui-mail">
    <div className="breadcrumbs-area">
        <ul>
            <li>
                <Link to="/">Trang chủ</Link>
            </li>
            
            <li>Danh sách phòng ban</li>
        </ul>
    </div>
    <div className="card mb-3">
      <div className="card-header">
        <div className="d-flex">
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={()=>setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>Email </label>
              <input type="text" placeholder="Tìm theo email nhận" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="sendmail" idrole="danh-sach-gui-mail" params={params} title="Danh sách gửi mail" defaultLimit={50}  serverSide={true} columns={columns}>
    
    </SRList>
  </AkkhorLayout>);
}
