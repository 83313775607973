
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";

export default function ViewEmail() {
    const { user } = useSelector((state) => state.auth);
    const  {id}  = useParams();
    const [detail, setDetail] = useState(null);

    useEffect(
        function () {
            if (id > 0) {
                Axios.get(URL_LIST_ALL + "sendmail/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            setDetail(res.data.data);
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
            }
        },[user,id]
      );
    
  return (
    <AkkhorLayout idrole="test-email">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Xem email đã gửi
                </li>
            </ul>
        </div>
      <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Xem email đã gửi</h3>
                </div>
            </div>
            {detail &&
                <div className="w-100 ">
                    <p className="w-100">Người nhận: {detail.mail_to}</p>
                    <p className="w-100">Tiêu đề: {detail.subject}</p>
                    <p className="w-100">Nội dung:</p>
                    <div className="w-100 border p-3" dangerouslySetInnerHTML={{ __html: detail.body }}>
                    </div>
                </div>
            }
        </div>
      </div>
    </AkkhorLayout>
  );
}
