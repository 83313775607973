import React from 'react';

export default function ItemRoleRight(props) {
    const { dt, RolesRight, name, marginleft , parent, onChange} = props;

    
    const changeRight =(status) => {
        let newDT= Object.assign({}, dt);
        if(parent !== name)
        {
            newDT[parent].children[name].checked = status;
            if(status)
            {
                newDT[parent].checked = status;
            }
        }else{
            newDT[name].checked = status;
            if(newDT[name].children)
            {
                Object.keys(newDT[name].children).map((key,i)=>{
                    newDT[name].children[key].checked = status;
                    return key
                })
            }
        }
        onChange(newDT)
    }
    
    return (
        <div style={{marginLeft: marginleft, marginBottom:5}}>
        <input type="checkbox" name={name} value="1" checked={RolesRight.checked} onChange={(e)=>changeRight(e.target.checked)} /> {RolesRight.title}
        
        {RolesRight.children &&<div className="row">{Object.keys(RolesRight.children).map((key,i)=><ItemRoleRight key={i} dt={dt} RolesRight={RolesRight.children[key]} name={key} marginleft={marginleft+50} parent={parent} onChange={onChange}  />)}</div>}
        
        </div>
    );
}
