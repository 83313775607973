
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";


export default function DanhSachLoaiVanThu() {
  const { user } = useSelector((state) => state.auth);
  const [params, setParam] = useState();
  const [title, setTitle] = useState();
  const [code, setCode] = useState();
  const [filterShow, setFilterShow] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (title && title !== '') _param += "&title=" + title
    if (code && code !== '') _param += "&code=" + title
    setParam(_param);
  };


  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tiêu đề" },
    { name: "code", label: "Mã văn thư" },
    { name: "code_vb", label: "Code văn bản" },
    { name: "part_name", label: "Phòng ban sử dụng" },
    { name: "company_name", label: "Công ty sử dụng" },

    { name: "datecreate", label: "Thời gian tạo" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Phân loại văn thư lưu trữ
        </li>
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">

            <div className="col-12 col-sm-3 form-group">
              <input type="text" placeholder="Tiêu đề" className="form-control height32" autoComplete="off" onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className="col-12 col-sm-3 form-group">
              <input type="text" placeholder="Mã" className="form-control height32" autoComplete="off" onChange={(e) => setCode(e.target.value)} />
            </div>

            <div className="col-12 col-sm-3 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="vanthu_param" idrole="vanthu_param" params={params} title="Văn thư lưu trữ" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>

  </AkkhorLayout>);
}
