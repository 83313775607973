
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function CaiDatNhanSu() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = {
        time_am_start: '', time_am_end: '', time_pm_start: '', time_pm_end: '', chotcong: '', hangiaitrinh: '', handuyetgiaitrinh: '', sendmail: 0,max_giaitrinh:''
    }
    useEffect(
        function () {

            let mounted = true;
            Axios.get(URL_LIST_ALL + "hrsettings/1", user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (mounted) {
                            setDetail(res.data.data);
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
            return () => (mounted = false);

        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            time_am_start: Yup.string().trim().nullable().required('Nhập thời gian bắt đầu ca sáng'),
            time_am_end: Yup.string().trim().nullable().required('Nhập thời gian kết thúc ca sáng'),
            time_pm_start: Yup.string().trim().nullable().required('Nhập thời gian bắt đầu ca chiều'),
            time_pm_end: Yup.string().trim().nullable().required('Nhập thời gian kết thúc ca chiều'),
            chotcong: Yup.string().trim().nullable().required('Nhập ngày chốt công hàng tháng'),
            max_giaitrinh: Yup.string().trim().nullable().required('Nhập giới hạn số lần giải trình công'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.put(URL_LIST_ALL + 'hrsettings/1', values, user.config).then(res => {
                setLoading(false);
                setSubmitting(false);
                setRefresh(!refresh)
                if (res.data.status === "success") {
                    resetForm();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật phòng ban thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));
        },
    });

    return (
        <AkkhorLayout idrole="cai-dat-nhan-su">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Cài đặt nhân sự</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Cài đặt nhân sự</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-4 form-group">
                                <label>Thời gian chấm vào ca sáng<span className="text-danger">*</span></label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_am_start")} />
                                <AlertErrorForm formik={formik} name="time_am_start"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Thời gian chấm ra ca sáng<span className="text-danger">*</span></label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_am_end")} />
                                <AlertErrorForm formik={formik} name="time_am_end"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Thời gian chấm vào ca chiều<span className="text-danger">*</span></label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_pm_start")} />
                                <AlertErrorForm formik={formik} name="time_pm_start"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Thời gian chấm ra ca chiều<span className="text-danger">*</span></label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_pm_end")} />
                                <AlertErrorForm formik={formik} name="time_pm_end"></AlertErrorForm>
                            </div>

                            <div className="col-12 col-sm-4 form-group">
                                <label>Ngày chốt công hàng tháng <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("chotcong")} />
                                <AlertErrorForm formik={formik} name="chotcong"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Thời hạn giải trình công (ngày) <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("hangiaitrinh")} />
                                <AlertErrorForm formik={formik} name="hangiaitrinh"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Thời hạn phê duyệt giải trình công (ngày) <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("handuyetgiaitrinh")} />
                                <AlertErrorForm formik={formik} name="handuyetgiaitrinh"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Giới hạn số lần giải trình công mỗi tháng <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("max_giaitrinh")} />
                                <AlertErrorForm formik={formik} name="max_giaitrinh"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Tự động gửi mail</label>
                                <select className="form-select height32" {...formik.getFieldProps("sendmail")}>
                                    <option value="1">Kích hoạt</option>
                                    <option value="0">Ngừng kích hoạt</option>
                                </select>
                            </div>
                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
