
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import { Link, useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert";


export default function TestEmail() {
    const { user } = useSelector((state) => state.auth);
    const  {id}  = useParams();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    
    const defaultdetail = {
        mailto:'',bodymail:'',mailcc:'',subject:''
    }
    useEffect(
        function () {
            
        },[user,id,refresh]
      );
    const formik = useFormik({
        initialValues:defaultdetail,
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
          mailto: Yup.string().trim().nullable().required('Nhập email gửi đi').max(150, "Độ dài không quá 150 ký tự"),
          subject: Yup.string().trim().nullable().required('Nhập tiêu đề').max(150, "Độ dài không quá 150 ký tự"),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            Axios.post(URL_LIST_ALL + 'test-email', values, user.config).then(res => {
                setLoading(false);
                setSubmitting(false);
                setRefresh(!refresh)
                if (res.data.status === "success") {
                    resetForm();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn đã gửi mail thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));
        },
    });

  return (
    <AkkhorLayout idrole="test-email">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Test gửi mail
                </li>
            </ul>
        </div>
      <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Test gửi mail</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-6 col-12 form-group">
                        <label>Địa chỉ mail <span className="text-danger">*</span></label>
                        <input type="email" placeholder="Nhập email" className="form-control height32" {...formik.getFieldProps("mailto")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="mailto"></AlertErrorForm>
                    </div>
                    <div className="col-12"></div>
                    <div className="col-lg-6 col-12 form-group">
                        <label>Tiêu đề</label>
                        <input type="text" placeholder="Nhập tiêu đề" className="form-control height32" {...formik.getFieldProps("subject")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="subject"></AlertErrorForm>
                    </div>
                    <div className="col-12"></div>

                    <div className="col-lg-6 col-12 form-group">
                        <label>CC: (phân cách nhau bằng dấu ;)</label>
                        <input type="text" placeholder="Nhập email CC " className="form-control height32" {...formik.getFieldProps("mailcc")} autoComplete="off"/>
                    </div>
                    <div className="col-12"></div>

                    <div className="col-lg-6 col-12 form-group">
                        <label>Nội dung <span className="text-danger">*</span></label>
                        <textarea type="email" placeholder="Nhập nội dung" className="form-control" {...formik.getFieldProps("bodymail")} autoComplete="off"></textarea>
                        <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                    </div>
                    <div className="col-xs-12"></div>
                   
                    
                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner />:<>Gửi</>}</button>
                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
