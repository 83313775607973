import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { LIST_NATION } from "../configs";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import { Link } from "react-router-dom";
import { checkRole } from "../model";
import Loading from "../component/Loading";
import UploadButton from "../component/UploadButton";


export default function ThongTinCaNhanForm() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [listHopDong, setListHopDong] = useState([]);
    const [dateSelect, setDateSelect] = useState({
        birthday: null, cccd_date: null, birthday_child_1: null, birthday_child_2: null, birthday_child_3: null, datejoining: null, ngaynghiviec: null, listhopdong: null,
        datehdtv_start: null, datehdtv_end: null, datehdld_start_1: null, datehdld_end_1: null, datehdld_start_2: null, datehdld_end_2: null, datehdld_start_3: null, datehdld_end_3: null, hd_status: null, ungphep: 0
    });

    const defaultdetail = {
        fullname: '',
        sex: '',
        mobile: '',
        email: '',
        birthday: '',
        nation: '',
        address: '',
        status: 3,
        cccd: '',
        company_id: null,
        part_id: null,
        chucvu_id: '',
        datejoining: '',
        role_id: 2,
        typeaccount: 2,
        dateCCCD: '',
        cccd_address: '',
        address_tc: '',
        dctc: '',
        bank_id: '',
        bank_address: '',
        bank_city: '',
        bhxh_id: '',
        mst_id: '',
        qualification: '',
        majors: '',
        highschool: '',
        marital_status: '',
        fullname_love: '',
        total_child: '',
        fullname_child_1: '',
        birthday_child_1: '',
        fullname_child_2: '',
        birthday_child_2: '',
        fullname_child_3: '',
        birthday_child_3: '',
        time_test: '',
        datehdtv_start: '',
        datehdtv_end: '',
        datehdld_start_1: '',
        datehdld_end_1: '',
        datehdld_start_2: '',
        datehdld_end_2: '',
        datehdld_start_3: '',
        datehdld_end_3: '',
        hd_status: '',
        luong_ct: '',
        ngaynghiviec: '',
        checkin_status: 1,
        usertype: 1,
    }
    useEffect(
        function () {

        }, [id, autoRefresh]
    );

    let validForm = {
        fullname: Yup.string().trim().nullable().required('Nhập họ và tên ').max(150, "Độ dài không quá 150 ký tự"),
        sex: Yup.string().trim().nullable().required('Chọn giới tính'),
        birthday: Yup.string().trim().nullable().required('Chọn ngày sinh'),
        mobile: Yup.string().trim().nullable().required('Nhập số điện thoại'),
        email: Yup.string().trim().nullable().required('Nhập địa chỉ email')
    }

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(validForm),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.post(URL_LIST_ALL + 'users_save', values, user.config).then(res => {
                setLoading(false);
                setSubmitting(false);
                setAutoRefresh(!autoRefresh)
                if (res.data.status === "success") {
                    resetForm();
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn thêm mới tài khoản thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => console.log(err));

        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    return (
        <div className="container">

            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Thêm thông tin cá nhân</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Giới tính <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("sex")}>
                                    <option value="" disabled>Hãy chọn giới tính</option>
                                    <option value="Nam">Nam</option>
                                    <option value="Nữ">Nữ</option>
                                    <option value="Khác">Khác</option>
                                </select>
                                <AlertErrorForm formik={formik} name="sex"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày sinh <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số CCCD/CMND: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Ngày cấp CCCD/CMND: </label>
                                <DateTimePicker onChange={(value) => { handleDate('cccd_date', value) }} value={dateSelect.cccd_date ? new Date(dateSelect.cccd_date) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Nơi cấp CCCD/CMND: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd_address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ thường trú: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address_tc")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Địa chỉ liên lạc</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Điện thoại <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Email</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
                                <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Dân tộc</label>
                                <select className="form-select height32" {...formik.getFieldProps("nation")}>
                                    <option value="" >Chọn dân tộc</option>
                                    {LIST_NATION.map((nation, index) => <option value={nation} key={index.toString()}>{nation}</option>)}
                                </select>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trình độ chuyên môn: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("qualification")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chuyên ngành: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("majors")} />
                            </div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Trường đào tạo: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("highschool")} />
                            </div>

                            <div className="col-12 mt-3" ></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tình trạng hôn nhân</label>
                                <select className="form-select height32" {...formik.getFieldProps("marital_status")}>
                                    <option value="Chưa kết hôn" >Chưa kết hôn</option>
                                    <option value="Đã kết hôn" >Đã kết hôn</option>
                                    <option value="Đã ly hôn" >Đã ly hôn</option>
                                </select>
                            </div>
                            {((formik.values.marital_status && formik.values.marital_status === "Đã kết hôn") || (formik.values.marital_status && formik.values.marital_status === "Đã ly hôn")) &&
                                <>
                                    {formik.values.marital_status === "Đã kết hôn" &&
                                        <>
                                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                                <label>Họ Tên vợ/chồng: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_love")} />
                                            </div>
                                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                                <label>Số điện thoại vợ/chồng: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("phone_love")} />
                                            </div>
                                        </>
                                    }

                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Số con cái: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("total_child")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 1: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_1")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 1: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_1', value) }} value={dateSelect.birthday_child_1 ? new Date(dateSelect.birthday_child_1) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 2: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_2")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 2: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_2', value) }} value={dateSelect.birthday_child_2 ? new Date(dateSelect.birthday_child_2) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Tên con 3: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname_child_3")} />
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                                        <label>Sinh nhật con 3: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('birthday_child_3', value) }} value={dateSelect.birthday_child_3 ? new Date(dateSelect.birthday_child_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                </>
                            }
                            <div className="col-12 mt-3" ><hr /></div>

                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số TK ngân hàng techcombank:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_id")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Chi nhánh:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_address")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Tỉnh/Thành phố:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_city")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Số sổ BHXH: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bhxh_id")} />
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12 form-group">
                                <label>Mã số thuế: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mst_id")} />
                            </div>

                            <div className="col-12 mt-3" ><hr /></div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu tài khoản</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {loading && (<Loading />)}
        </div>
    );
}
