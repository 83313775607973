
import React from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function ListRolesPage() {
  let params = '';
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true } },
    { name: "datecreate",label: "Ngày đăng",}
  ];
  
  return (<AkkhorLayout idrole="list-roles">
    <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Phân quyền
                </li>
                
            </ul>
        </div>
    <SRList name="roles" idrole="list-roles" params={params} title="Quyền hạn" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>);
}
