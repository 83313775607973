
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function FormQuanLyCa() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = {
        title: '', timestart: '', reststart: '', restend: '', timeend: '', status: 1
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "quanlyca/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) setDetail(res.data.data);
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên ca làm việc').max(150, "Độ dài không quá 150 ký tự"),
            timestart: Yup.string().trim().nullable().required('Nhập thời gian bắt đầu'),
            timeend: Yup.string().trim().nullable().required('Nhập thời gian kết thúc'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'quanlyca/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật ca thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                // console.log(values);
                Axios.post(URL_LIST_ALL + 'quanlyca', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới ca thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="quan-ly-ca">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/quan-ly-ca">Danh sách ca làm việc</Link>
                    </li>
                    <li>{id ? 'Sửa ca làm việc' : 'Thêm mới ca làm việc'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Ca làm việc</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3 col-12 form-group">
                                <label>Tên ca làm việc <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>

                            <div className="col-lg-3 col-12 form-group">
                                <label>Thời gian bắt đầu ca <span className="text-danger">*</span></label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("timestart")} />
                                <AlertErrorForm formik={formik} name="time_start"></AlertErrorForm>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Bắt đầu nghỉ giữa ca</label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("reststart")} />
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Kết thúc nghỉ giữa ca</label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("restend")} />
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Thời gian kết thúc ca<span className="text-danger">*</span></label>
                                <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("timeend")} />
                                <AlertErrorForm formik={formik} name="timeend"></AlertErrorForm>
                            </div>

                            <div className="col-xs-12"></div>
                            <div className="col-lg-3 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
