import React, { useState, useEffect } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useSelector } from "react-redux";
// import MUIDataTable from "mui-datatables";
import Loading from "./Loading";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { checkRole } from "../model";

function BangChamCongTable(props) {
    const parsed = window.location.search.replace('?', '');
    let { children, name, idrole, linkaction, defaultLimit, params, columns, onlyList, moresearch, refresh, TopBar } = props;
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const limit = defaultLimit ? defaultLimit : 10;
    const [totalRecord, setTotalRecord] = useState(defaultLimit ? defaultLimit : 10);
    const [dayOfMonth, setDayOfMonth] = useState(null)
    const [itemActive, setItemActive] = useState(null)
    const allowExport = checkRole(user, idrole, 'export');
    const allowChotcong = checkRole(user, idrole, 'chotcong');

    useEffect(
        function () {
            let mounted = true;
            if (mounted) {
                setRows([]);
                setLoading(true)
            }
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'Content-Type': 'application/json; charset=UTF-8',
                    'search': JSON.stringify(moresearch)
                }
            }
            Axios.get(URL_LIST_ALL + name + "?&page=" + page + "&limit=" + limit + (params ? '&' + params : '') + (parsed ? '&' + parsed : ''), config)
                .then((res) => {
                    if (mounted) setLoading(false)
                    if (res.data.status === "success") {
                        if (mounted) {
                            setRows(res.data.data);
                            setTotalRecord(res.data.total);
                            setDayOfMonth(res.data.day_of_month)
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => {
                    if (mounted) setLoading(false)
                });

            return () => (mounted = false);
        },
        [name, user, page, limit, params, parsed, moresearch, refresh]
    );

    return (
        <>
            {loading && (<Loading />)}
            {!onlyList && <div className="d-flex justify-content-end">
                {TopBar && <>{TopBar}</>}

                {allowChotcong &&
                    <Link
                        to={"/chotbangchamcong"}
                        className="btn btn-danger m-2"
                        style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
                        <i className="fas fa-plus-"></i> Chốt công
                    </Link>}
                {allowExport &&
                    <Link
                        to={"/export-" + (linkaction ? linkaction : name)}
                        className="btn btn-info m-2"
                        style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
                        <i className="fas fa-plus-"></i> Xuất file
                    </Link>}
               
            </div>}
            {children}

            {columns && columns.length > 0 &&
                <>
                    <div className="table-cham-cong">
                        <div className="wraper-table">
                            <div className="table-header">
                                <div className="table-tr">
                                    {columns.map((item, i) => {
                                        return <div className={"item-th item-td td-" + item.name} key={i}>{item.label}</div>
                                    })}
                                    {dayOfMonth && dayOfMonth.map((item, i) => {
                                        return <div className={"item-th item-td td-day head-day"} key={i}>
                                            {item.date && item.day ? <div>
                                                <div className="day">{item.date}</div>
                                                <div className="date">{item.day}</div>
                                            </div> : <>
                                                {item.lable && item.lable}
                                            </>}

                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="table-body">

                                {rows && rows.length > 0 && rows.map((itemTr, iTr) => {
                                    // let idrow = itemTr.id;
                                    return (
                                        <div className={itemActive && itemActive === "tr" + iTr ? "table-tr tr-active" : "table-tr"} key={iTr} onClick={() => setItemActive(itemActive === "tr" + iTr ? null : "tr" + iTr)}>
                                            {columns.map((itemTd, iTd) => {
                                                let result = itemTr[itemTd.name]
                                                return <div className={"item-th item-td td-" + itemTd.name} key={iTd}>{result}</div>
                                            })}
                                            {itemTr.useronday.map((itemTd, iTd) => {
                                                return <div className={"item-th item-td td-day td-day-" + itemTd.day} key={iTd}>
                                                    {itemTd.day ? itemTd.val : <span className="text-success">{itemTd.val}</span>}
                                                </div>
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalRecord}
                        pageRangeDisplayed={5}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="<"
                        nextPageText=">"
                        onChange={setPage}
                    />
                </>
            }

        </>
    );
}
export default React.memo(BangChamCongTable)
//https://github.com/gregnb/mui-datatables