
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import AlertErrorForm from "../component/AlertErrorForm";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormXinNghi() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [listQuanLy, setListQuanLy] = useState(null);

    const defaultdetail = { fullname: user ? user.data.fullname : '', part: user ? user.data.part_title : '', company: user ? user.data.company_title : '', user_code: user ? user.data.code : '', type: 'Nghỉ phép', date_start: '', checker_code: '' }

    useEffect(function () {
        console.log(user.data);
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "file_attachment/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        setDetail(res.data.data);
                        const date_start = res.data.data.date_start
                        const date_end = res.data.data.date_end
                        setStartDate(moment(date_start, 'YYYY-MM-DD'))
                        setEndDate(moment(date_end, 'YYYY-MM-DD'))
                        const list_censorship = res.data.data.list_censorship
                        setListQuanLy(list_censorship)
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [user, id]);// eslint-disable-line

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            reason: Yup.string().trim().nullable().required('Vui lòng nhập lý do').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            values.list_checker = listQuanLy
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'file_attachment/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật Đăng ký nghỉ, đi muộn, về sớm, công tác thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'file_attachment', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới Đăng ký nghỉ, đi muộn, về sớm, công tác thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleCallback = async (start, end, label) => {
        console.log(start);
        setStartDate(start)
        setEndDate(end)
        await formik.setFieldValue('date_start', start.format('YYYY-MM-DD'))
        await formik.setFieldValue('date_end', end.format('YYYY-MM-DD'))
    }


    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/danh-sach-dang-ky-lam-online">Danh sách đăng ký làm Online</Link>
                    </li>
                    <li>{id ? 'Cập nhập đăng ký làm Online' : 'Đăng ký làm Online'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Đăng ký làm Online</h3>
                        </div>
                    </div>
                    {id > 0 && detail && detail.status !== 0 ?
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <table className="table table-borderless table-infomation">
                                    <tbody>
                                        {
                                            detail && <>
                                                <tr>
                                                    <td><b>Họ và tên:</b> </td>
                                                    <td>{detail.fullname ? detail.fullname : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Mã số nhân viên:</b> </td>
                                                    <td>{detail.user_code ? detail.user_code : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Phòng ban:</b> </td>
                                                    <td>{detail.part ? detail.part : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Công ty:</b> </td>
                                                    <td>{detail.company ? detail.company : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Đăng ký:</b> </td>
                                                    <td>{detail.type ? detail.type : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Lý do:</b> </td>
                                                    <td>{detail.reason ? detail.reason : ''}</td>
                                                </tr>

                                                <tr>
                                                    <td><b>Số ngày làm online:</b> </td>
                                                    <td>{detail && detail.number ? detail.number : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Ngày bắt đầu:</b> </td>
                                                    <td>{detail && detail.date_start ? detail.date_start : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Ngày kết thúc:</b> </td>
                                                    <td>{detail && detail.date_end ? detail.date_end : ''}</td>
                                                </tr>


                                            </>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 mt-3"></div>
                            {console.log(listQuanLy)}
                            {user && listQuanLy && listQuanLy.map((item, i) => {
                                return <div className="col-auto form-group text-center" key={i}>
                                    <label className="w-100"><b>{item.fullname}</b></label>
                                    {item.status === 0 && <span>Chờ duyệt</span>}
                                    {item.status === 1 && <span>Đã phê duyệt</span>}
                                    {item.status === 2 && <span>Không phê duyệt</span>}
                                    {item.status === 3 && <span>Đang xem xét</span>}
                                    {item.comment && <p>{item.comment}</p>}
                                </div>
                            })}

                        </div>
                        :
                        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Họ và tên <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                </div>
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Mã số nhân viên <span className="text-danger">*</span></label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("user_code")} autoComplete="off" />
                                </div>
                                <div className="col-12"></div>
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Phòng ban </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("part")} autoComplete="off" />
                                </div>

                                <div className="col-sm-4 col-12 form-group">
                                    <label>Công ty </label>
                                    <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("company")} autoComplete="off" />
                                </div>
                                <div className="col-12"></div>

                                <div className="col-lg-8 col-12 form-group">
                                    <label>Lý do</label>
                                    <textarea className="form-control" {...formik.getFieldProps("reason")}></textarea>

                                    <AlertErrorForm formik={formik} name="reason"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>

                                <div className="col-lg-8 col-12 form-group">
                                    <label>Số ngày làm online</label>
                                    <input type="number" placeholder="" className="form-control height32" {...formik.getFieldProps("number")} autoComplete="off" />
                                </div>

                                <div className="col-12"></div>
                                <div className="col-md-8 col-12 form-group">
                                    <label>Thời gian nghỉ/ công tác </label>
                                    <DateRangePicker initialSettings={{ startDate: startDate ? startDate : 0, endDate: endDate ? endDate : 0, maxDate: "", }} onCallback={handleCallback}>
                                        <div className='d-flex align-items-center'>
                                            <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                                            <input
                                                className="form-control h-31 form-control-sm"
                                                placeholder='Từ ngày'
                                                readOnly={true}
                                                onChange={(e) => setStartDate(e)}
                                                value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                                            />
                                            <span className='fas fa-arrow-right mx-4' />
                                            <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                                            <input
                                                className="form-control h-31 form-control-sm"
                                                placeholder='Đến ngày'
                                                readOnly={true}
                                                onChange={(e) => setEndDate(e)}
                                                value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                                            />
                                        </div>
                                    </DateRangePicker>
                                </div>

                                <div className="col-12 mt-3"></div>
                                <div className="col-xl-8 col-lg-6 col-12 form-group">
                                    <label>Người phê duyệt</label>
                                    <SearchBoxModal apiname={"users"} placeholder="Chọn người phê duyệt" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['checker_code']} name='checker_code'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('checker_code', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="checker_code"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-12 form-group mg-t-8">
                                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu đăng ký</>}</button>
                                    <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </AkkhorLayout>
    );
}
