import React, { useState, useEffect } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useSelector } from "react-redux";
// import MUIDataTable from "mui-datatables";
import Loading from "./Loading";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { checkRole } from "../model";
import { confirmAlert } from "react-confirm-alert";

function BangChotCongTable(props) {
    const parsed = window.location.search.replace('?', '');
    let { children, name, idrole, linkaction, defaultLimit, params, columns, onlyList, moresearch, refresh, TopBar } = props;
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const limit = defaultLimit ? defaultLimit : 10;
    const [totalRecord, setTotalRecord] = useState(defaultLimit ? defaultLimit : 10);
    const [itemActive, setItemActive] = useState(null)
    const allowExport = checkRole(user, idrole, 'export');
    const allowChotcong = checkRole(user, idrole, 'chotcong');
    const [change, setChange] = useState(false);

    useEffect(
        function () {
            let mounted = true;
            if (mounted) {
                setRows([]);
                setLoading(true)
            }
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'Content-Type': 'application/json; charset=UTF-8',
                    'search': JSON.stringify(moresearch)
                }
            }
            Axios.get(URL_LIST_ALL + name + "?&page=" + page + "&limit=" + limit + (params ? '&' + params : '') + (parsed ? '&' + parsed : ''), config)
                .then((res) => {
                    if (mounted) setLoading(false)
                    if (res.data.status === "success") {
                        if (mounted) {
                            setRows(res.data.data);
                            setTotalRecord(res.data.total);
                           
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => {
                    if (mounted) setLoading(false)
                });

            return () => (mounted = false);
        },
        [name, user, page, limit, params, parsed, moresearch, refresh]
    );


  const chotlaicong = (code,time) =>{
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn chốt lại công cho nhân sự này ? ",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          Axios.get(URL_LIST_ALL  + 'chotlaicong?code=' + code + '&time='+time, user.config).then(res => {
            setLoading(false)
            if (res.data.status === "success") {
              setChange(!change);
              alert(res.data.message);
            } else alert(res.data.message);
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }
  const guilaimail = (code,time) =>{
    confirmAlert({
        title: "Thông báo",
        message: "Bạn muốn chốt báo cáo và gửi email cho nhân sự này ?",
        buttons: [{
          label: "Có",
          onClick: () => {
            Axios.get(URL_LIST_ALL + 'chotbaocao?chot=1&time=' + time + '&code=' + code, user.config).then(res => {
              if (res.data.status === "success") {
                alert("Chốt thành công");
              } else alert(res.data.message);
            }).catch(err => { console.log(err); });
          },
        }, { label: "Không" },
        ],
      });
  }
  const molaicong = (code,time) =>{
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn mở lại các bản đăng ký/giải trình công cho nhân sự này ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          Axios.get(URL_LIST_ALL  + 'molaicong?code=' + code + '&time='+time, user.config).then(res => {
            setLoading(false)
            if (res.data.status === "success") {
                alert(res.data.message);
              setChange(!change);
            } else alert(res.data.message);
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }

    return (
        <>
            {loading && (<Loading />)}
            {!onlyList && <div className="d-flex justify-content-end">
                {TopBar && <>{TopBar}</>}

                {allowChotcong &&
                    <Link
                        to={"/chotbangchamcong"}
                        className="btn btn-danger m-2"
                        style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
                        <i className="fas fa-plus-"></i> Chốt công
                    </Link>}
                {allowExport &&
                    <Link
                        to={"/export-" + (linkaction ? linkaction : name)}
                        className="btn btn-info m-2"
                        style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
                        <i className="fas fa-plus-"></i> Xuất file
                    </Link>}

            </div>}
            {children}

            {columns && columns.length > 0 &&
                <>
                    <div className="table-cham-cong table-chotcong">
                        <div className="wraper-table">
                            <div className="table-header">
                                <div className="table-tr">
                                    {columns.map((item, i) => {
                                        return <div className={"item-th item-td td-" + item.name} key={i}>{item.label}</div>
                                    })}
                                    <div className="item-th item-td td-action">Thao tác</div>
                                </div>

                            </div>
                            <div className="table-body ">

                                {rows && rows.length > 0 && rows.map((itemTr, iTr) => {
                                    // let idrow = itemTr.id;
                                    return (
                                        <div className={itemActive && itemActive === "tr" + iTr ? "table-tr tr-active" : "table-tr"} key={iTr} onClick={() => setItemActive(itemActive === "tr" + iTr ? null : "tr" + iTr)}>
                                            {columns.map((itemTd, iTd) => {
                                                let result = itemTr[itemTd.name]
                                                return <div className={"item-th item-td td-" + itemTd.name} key={iTd}>{result}</div>
                                            })}
                                            <div className="item-th item-td td-action">
                                                <button className="btn btn-sm btn-success mt-1" onClick={() => { chotlaicong(itemTr.code,itemTr.year+'-'+itemTr.month) }} data-toggle="tooltip" data-placement="bottom" title="Chốt lại bảng chấm công"><i className="fas fa-exchange-alt"></i> Chốt lại</button>
                                                <button className="btn btn-sm btn-info mt-1" onClick={() => { guilaimail(itemTr.code,itemTr.year+'-'+itemTr.month) }} data-toggle="tooltip" data-placement="bottom" title="Gửi lại"><i className="fas fa-exchange-alt"></i> Gửi lại</button>
                                                <button className="btn btn-sm btn-warning mt-1 ml-1" onClick={() => { molaicong(itemTr.code,itemTr.year+'-'+itemTr.month) }} data-toggle="tooltip" data-placement="bottom" title="Mở lại bảng chấm công"><i className="fas fa-exchange-alt"></i> Mở lại</button>
                                                <a className="btn btn-sm btn-warning mt-1 ml-1" href={"/edit-bangchotcongthang/"+itemTr.id} data-toggle="tooltip" data-placement="bottom" title="Sửa"><i className="fas fa-pencil-alt"></i> Sửa</a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalRecord}
                        pageRangeDisplayed={5}
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="<"
                        nextPageText=">"
                        onChange={setPage}
                    />
                </>
            }

        </>
    );
}
export default React.memo(BangChotCongTable)
//https://github.com/gregnb/mui-datatables