
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import { checkRole } from "../model";
import DateRangePicker from "react-bootstrap-daterangepicker";
import UserDropDown from "../component/UserDropDown";
import { Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";

export default function DanhSachThucTapSinh() {

  const { user } = useSelector((state) => state.auth);

  const [params, setParam] = useState('usertype=2');
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [fullname, setFullname] = useState('');
  const [code, setCode] = useState('');
  const [typeAccount, setTypeAccount] = useState('all');
  const [role_id, setRole_Id] = useState('all');
  const [part_id, setPart_Id] = useState();
  const [company_id, setCompany_Id] = useState();
  const [chucvu_id, setChucvu_Id] = useState('all');
  const [email, setEmail] = useState("");
  const [filterShow, setFilterShow] = useState(false)

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = 'usertype=2'
    if (typeAccount > -1) _param += "&typeaccount=" + typeAccount
    if (role_id > 0) _param += "&role_id=" + role_id
    if (part_id) _param += "&part_id=" + part_id
    if (company_id) _param += "&company_id=" + company_id
    if (chucvu_id > 0) _param += "&chucvu_id=" + chucvu_id
    if (fullname !== '') _param += "&fullname=" + fullname
    if (code !== '') _param += "&code=" + code
    if (email !== '') _param += "&email=" + email
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    console.log(_param);
    setParam(_param);
  };

  const allowLogin = checkRole(user, 'list-users', 'login');
  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "code", label: "Mã tập sinh" },
    { name: "sex", label: "Giới tính" },
    { name: "mobile", label: "Điện thoại", width: 150 },
    { name: "email", label: "Email", width: 250 },
    { name: "part_title", label: "Phòng ban" },
    { name: "chucvu", label: "Chức vụ", },
    { name: "company", label: "Công ty", },
    { name: "role_title", label: "Quyền hạn", },
    { name: "datecreate", label: "Ngày tạo" }

  ];
  if (allowLogin) {
    columns.push({ name: "id", label: "Đăng nhập", type: "fastlogin" })
  }

  const listTypeAccount = [
    { value: "all", name: "Tất cả" },
    { value: "1", name: "Quản trị viên" },
    { value: "2", name: "Tài khoản thường" },

  ]

  return (<AkkhorLayout idrole="list-thuc-tap-sinh">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Danh sách thực tập sinh
        </li>
      </ul>
    </div>

    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên thực tập sinh </label>
              <input type="text" placeholder="Tìm theo tên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã thực tập sinh </label>
              <input type="text" placeholder="Tìm theo mã thực tập sinh" value={code} onChange={(e) => setCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Email</label>
              <input type="text" placeholder="Tìm theo email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-12 form-group">
              <label>Loại tài khoản </label>
              <UserDropDown data={listTypeAccount} placeholder="Chọn loại tài khoản" defaultValue={typeAccount} name="typeAccount"
                onChange={async (e) => { setTypeAccount(e); }} all={true}
              ></UserDropDown>
            </div>

            <div className="col-xl-2 col-lg-6 col-12 form-group">
              <label>Công ty </label>
              <SearchBoxModal apiname={"company"} placeholder="Chọn Công ty" colvalue={'id'} colname={'title'} name='company_id'
                onChange={(e) => {
                  setCompany_Id(e)
                }}></SearchBoxModal>
            </div>
            <div className="col-xl-2 col-lg-6 col-12 form-group">
              <label>Phòng ban </label>
              <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} name='part_id'
                onChange={(e) => {
                  setPart_Id(e)
                }}></SearchBoxModal>
            </div>

            <div className="col-xl-2 col-lg-6 col-12 form-group">
              <label>Chức vụ </label>
              <SearchBoxModal apiname={"chucvu"} placeholder="Chọn chức vụ" colvalue={'id'} colname={'title'}  name='chucvu_id'
                onChange={(e) => {
                  setChucvu_Id(e)
                }}></SearchBoxModal>
            </div>

            <div className="col-xl-2 col-lg-6 col-12 form-group">
              <label>Quyền </label>
              <SearchBoxModal apiname={"roles"} placeholder="Chọn chức vụ" colvalue={'id'} colname={'title'}  name='role_id'
                onChange={(e) => {
                  setRole_Id(e)
                }}></SearchBoxModal>
            </div>

            <div className="col-md-6 col-12 form-group">
              <label> Thời gian tạo </label>
              <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "", }}
                onCallback={handleCallback}
              >
                <div className='d-flex align-items-center'>
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setStartDate(e)}
                    value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                  />
                  <span className='fas fa-arrow-right mx-4' />
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setEndDate(e)}
                    value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                  />
                </div>
              </DateRangePicker>
            </div>
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="users" idrole="list-thuc-tap-sinh" linkaction="thuc-tap-sinh" params={params} title="Tài khoản" defaultLimit={20} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
