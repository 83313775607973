import React from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import Moment from "react-moment";
import { Formik } from "formik";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";

function ItemBaoCaoCong(props) {
    let { list, parent_counter, refresh, setRefresh, loading, setLoading } = props;
    const { user } = useSelector((state) => state.auth);

    return (<div key={parent_counter}>
        {list.map((item, i) => {
            return (<React.Fragment key={item.ID} >
                <div className="table-tr item-cv" >
                    <div className="item-th item-td item-stt">{parent_counter && parent_counter + '.'}{item.counter}</div>
                    {/* <div className="item-th item-td item-stt">{item.ID}</div> */}
                    <div className="item-th item-td item-title">
                        {(item.childrent && item.childrent.length > 0) ?
                            <strong>{item.TITLE}</strong>
                            :
                            <span>{item.TITLE}</span>
                        }
                        {item.checklist && item.checklist.length > 0 &&
                        item.checklist.map((itCheck, ic) => {
                          return <div className={"w-100 itemCheckList d-flex justify-content-between " + (itCheck.TITLE.includes('#') ? 'mt-2 ' : 'pl-3')} key={ic}>
                            {itCheck.TITLE.includes('#') ? 
                            <>
                              <strong> {itCheck.TITLE} </strong>
                            </>
                            : 
                            <>
                              <span>{itCheck.SORT_INDEX + 1}. {itCheck.TITLE}</span> 
                              {itCheck.IS_COMPLETE === 'Y' ? <span className="fas fa-check-circle text-success"></span> : <span className="far fa-circle"></span>}
                            </>
                            
                            } 
                          </div>
                        })
                      }
                    </div>
                    <div className="item-th item-td item-desc" dangerouslySetInnerHTML={{ __html: item.DESCRIPTION_HTML }} />
                    <div className="item-th item-td item-status">
                        {item.STATUS == 1 && ""}
                        {item.STATUS == 2 && <span className="font-weight-bold bg-warning">Đang chuẩn bị</span>}
                        {item.STATUS == 3 && <span className="font-weight-bold bg-info">Đang tiến hành</span>}
                        {item.STATUS == 4 && <span className="font-weight-bold bg-success">Đã hoàn thành</span>}
                        {item.STATUS == 5 && (item.DEADLINE_TIME ? <span className="font-weight-bold bg-danger">Đã đóng <br /><small>(Chậm deadline)</small></span> : <span className="font-weight-bold bg-success">Đã đóng</span>)}
                        {item.STATUS == 6 && <span className="font-weight-bold bg-xdanger">Đã quá hạn</span>}
                    </div>
                    <div className="item-th item-td item-datecreate">
                        {item.CREATED_DATE && <Moment format="DD-MM-YYYY">{new Date(item.CREATED_DATE)}</Moment>}
                    </div>
                    <div className="item-th item-td item-datestart">
                        {item.DATE_START && <Moment format="DD-MM-YYYY">{new Date(item.DATE_START)}</Moment>}
                    </div>
                    <div className="item-th item-td item-deadline">
                        {item.DEADLINE && <Moment format="DD-MM-YYYY">{new Date(item.DEADLINE)}</Moment>}
                    </div>
                    <div className="item-th item-td item-dateend">
                        {item.CLOSED_DATE && <Moment format="DD-MM-YYYY">{new Date(item.CLOSED_DATE)}</Moment>}
                    </div>
                    <div className="item-th item-td item-dateend">
                        {item.DEADLINE_TIME ? item.DEADLINE_TIME : ''}
                    </div>
                    <div className="item-th item-td item-author">
                        {item.CREATED_BY_NAME}
                    </div>
                    <div className="item-th item-td item-trienkhai">
                        {item.RESPONSIBLE_NAME}
                    </div>
                    <div className="item-th item-td item-thamgia">
                        {item.LIST_MEMBER && item.LIST_MEMBER.map(function (itMem, iMem) {
                            return <p key={iMem}><span>{itMem}</span><br /></p>
                        })}
                    </div>
                    <div className="item-th item-td item-quansat">
                        {item.LIST_JOIN && item.LIST_JOIN.map(function (itJoin, iJoin) {
                            return <p key={iJoin}><span>{itJoin}</span><br /></p>
                        })}
                    </div>
                    <div className="item-th item-td item-kiennghi">
                        {item.PROPOSE ?
                            <div className="group-edit ">
                                <Formik
                                    initialValues={{ param_value: item.PROPOSE }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        let Data = { task_id: item.ID, param_key: 'PROPOSE', param_value: values.param_value }
                                        Axios.put(URL_LIST_ALL + 'param_tasks/' + item.PROPOSE_ID, Data, user.config).then(res => {
                                            setLoading(false);
                                            setSubmitting(false);
                                            if (res.data.status === "success") {
                                                confirmAlert({
                                                    title: 'Thành công',
                                                    message: 'Đề xuất kiến nghị thành công',
                                                    buttons: [{ label: 'OK' }]
                                                });
                                                setRefresh(!refresh)
                                            } else {
                                                confirmAlert({
                                                    title: 'Lỗi',
                                                    message: res.data.message,
                                                    buttons: [{ label: 'OK' }]
                                                });
                                            }
                                        }).catch(err => console.log(err));
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                                        <form className="d-flex w-100" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <textarea name="param_value" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                                    {loading ? <Spinner /> : "Lưu"}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            :
                            <div className="group-add">
                                <Formik
                                    initialValues={{ param_value: '' }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        let Data = { task_id: item.ID, param_key: 'PROPOSE', param_value: values.param_value }
                                        Axios.post(URL_LIST_ALL + 'param_tasks', Data, user.config).then(res => {
                                            setLoading(false);
                                            setSubmitting(false);
                                            if (res.data.status === "success") {
                                                confirmAlert({
                                                    title: 'Thành công',
                                                    message: 'Đề xuất kiến nghị thành công',
                                                    buttons: [{ label: 'OK' }]
                                                });
                                                setRefresh(!refresh)
                                            } else {
                                                confirmAlert({
                                                    title: 'Lỗi',
                                                    message: res.data.message,
                                                    buttons: [{ label: 'OK' }]
                                                });
                                            }
                                        }).catch(err => console.log(err));
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        <form className="d-flex w-100" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <textarea name="param_value" className="form-control" placeholder="Nhập nội dung..." onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                                    {loading ? <Spinner /> : "Lưu"}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        }
                    </div>
                    <div className="item-th item-td item-note">
                        {item.NOTE ?
                            <div className="group-edit">
                                <Formik
                                    initialValues={{ param_value: item.NOTE }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        let Data = { task_id: item.ID, param_key: 'NOTE', param_value: values.param_value }
                                        Axios.put(URL_LIST_ALL + 'param_tasks/' + item.NOTE_ID, Data, user.config).then(res => {
                                            setLoading(false);
                                            setSubmitting(false);
                                            if (res.data.status === "success") {
                                                confirmAlert({
                                                    title: 'Thành công',
                                                    message: 'Đề xuất kiến nghị thành công',
                                                    buttons: [{ label: 'OK' }]
                                                });
                                                setRefresh(!refresh)
                                            } else {
                                                confirmAlert({
                                                    title: 'Lỗi',
                                                    message: res.data.message,
                                                    buttons: [{ label: 'OK' }]
                                                });
                                            }
                                        }).catch(err => console.log(err));
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                                        <form className="d-flex w-100" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <textarea name="param_value" className="form-control" onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                                    {loading ? <Spinner /> : "Lưu"}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            :
                            <div className="group-add">
                                <Formik
                                    initialValues={{ param_value: '' }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        let Data = { task_id: item.ID, param_key: 'NOTE', param_value: values.param_value }
                                        Axios.post(URL_LIST_ALL + 'param_tasks', Data, user.config).then(res => {
                                            setLoading(false);
                                            setSubmitting(false);
                                            if (res.data.status === "success") {
                                                confirmAlert({
                                                    title: 'Thành công',
                                                    message: 'Ghi chú nghị thành công',
                                                    buttons: [{ label: 'OK' }]
                                                });
                                                setRefresh(!refresh)
                                            } else {
                                                confirmAlert({
                                                    title: 'Lỗi',
                                                    message: res.data.message,
                                                    buttons: [{ label: 'OK' }]
                                                });
                                            }
                                        }).catch(err => console.log(err));
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        <form className="d-flex w-100" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <textarea name="param_value" className="form-control" placeholder="Nhập nội dung..." onChange={handleChange} onBlur={handleBlur} value={values.param_value} />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-sm btn-success" disabled={isSubmitting}>
                                                    {loading ? <Spinner /> : "Lưu"}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        }
                    </div>
                </div>
                {item.childrent && item.childrent.length > 0 && <ItemBaoCaoCong list={item.childrent} parent_counter={item.counter} setRefresh={setRefresh} refresh={refresh} loading={loading} setLoading={setLoading} />}
            </React.Fragment>
            )
        })}
    </div>
    );
}
export default React.memo(ItemBaoCaoCong)
//https://github.com/gregnb/mui-datatables