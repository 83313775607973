
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useLocation,Link } from "react-router-dom";
import BangChamCongTable from "../component/BangChamCongTable";
import SearchBoxModal from "../component/SearchBoxModal";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function BangChamCongThang() {
  let query = useQuery();
  const [params, setParam] = useState('');
  const [code, setCode] = useState(parseInt(query.get("code")) ? parseInt(query.get("code")) : '');
  const [fullname, setFullname] = useState('');
  const [part_id, setPart_Id] = useState();
  const [email, setEmail] = useState("");
  const [filterShow, setFilterShow] = useState(false)
  const [month, setMonth] = useState([])
  const [year, setYear] = useState([])
  const [fMonth, setFMonth] = useState(parseInt(new Date().getMonth()) + 1)
  const [fYear, setFYear] = useState(new Date().getFullYear())

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "code", label: "MSNV" },
    { name: "part", label: "Phòng/ban" },
    { name: "company", label: "Công ty" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (part_id) _param += "&part_id=" + part_id
    if (fullname !== '') _param += "&fullname=" + fullname
    if (code !== '') _param += "&code=" + code
    if (email !== '') _param += "&email=" + email
    if (fYear && fMonth && fMonth !== '' && fYear !== '') {
      _param += "&time=" + fYear + '-' + fMonth
    }else{
      _param += "&time=" + (new Date()).getFullYear() + '-' + parseInt(new Date().getMonth()) + 1
    }
    setParam(_param);
  };
  
  useEffect(function () {
    let _month = []
    for (let m = 1; m <= 12; m++) {
      _month.push(m)
    }
    setMonth(_month)
    let _year = []
    let maxYear = new Date().getFullYear()
    for (let y = 2021; y <= maxYear + 1; y++) {
      _year.push(y)
    }
    setYear(_year)
  }, []);

  return (<AkkhorLayout idrole="list-cham-cong-thang">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Bảng chấm công tháng
        </li>
        
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      {/* {console.log(params)} */}
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên nhân viên </label>
              <input type="text" placeholder="Tìm theo tên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã nhân viên </label>
              <input type="text" placeholder="Tìm theo mã nhân viên" value={code} onChange={(e) => setCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Email</label>
              <input type="text" placeholder="Tìm theo email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-xl-2 col-lg-6 col-12 form-group">
              <label>Phòng ban </label>
              <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} name='part_id'
                onChange={(e) => {
                  setPart_Id(e)
                }}></SearchBoxModal>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <div className="row">
                <div className="col-lg-6 col-12 form-group">
                  <label>Chọn tháng</label>
                  <select className="form-select height32" onChange={(e) => { setFMonth(e.target.value) }} defaultValue={fMonth}>
                    {month && month.map((item, i) => {
                      return <option key={i} value={item < 10 ? '0' + item.toString() : item} selected={fMonth === item ? true : false}>Tháng {item}</option>
                    })}
                  </select>
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <label>Chọn năm</label>
                  <select className="form-select height32" onChange={(e) => { setFYear(e.target.value) }} defaultValue={fYear}>
                    {year && year.map((item, i) => {
                      return <option key={i} value={item < 10 ? '0' + item.toString() : item} selected={fYear === item ? true : false}>{item}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="col-12">
      <div className="font-13">
        <span>KC: Không chấm công</span>
        <span> - KV: Không chấm công vào</span>
        <span> - KR: Không chấm công ra</span>
        <span> - VM: Vào muộn</span>
        <span> - RS: Ra sớm</span>
        <span> - MS: Vào muộn và Ra sớm</span>
        <span> - GT: Đã giải trình</span>
        <span> - P: Nghỉ phép</span>
        <span> - KL: Nghỉ không lương</span>
        <span> - NB: Nghỉ bù</span>
        <span> - CĐ: Nghỉ Chế độ</span>
        <span> - NO: Nghỉ ốm</span>
        <span> - OL: Làm online</span>
      </div>
    </div>
    <BangChamCongTable name="users_onmonth" linkaction="bang-cham-cong-thang" idrole="list-cham-cong-thang" params={params} title="Tài khoản" defaultLimit={50} serverSide={true} columns={columns} />
  </AkkhorLayout>);
}
