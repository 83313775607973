
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function SuaBangChamCongChiTiet() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = {
        author_id: user.data.id, title: '', company_id: '', time_start: '', type: "Chấm vào", level: 1, comment: ''
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users_onday/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'users_onday/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật cài đặt thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } 
        },
    });

    return (
        <AkkhorLayout idrole="list-users_onday">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-users_onday">Bảng chấm công chi tiết</Link>
                    </li>
                    <li>Cập nhập chấm công</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Sửa chấm công</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-12 col-sm-6 form-group">
                                <label>Mã nhân viên</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("code")} readOnly />
                            </div>

                            <div className="col-12 col-sm-6 form-group">
                                <label>Ngày chấm công</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("datecheck")} readOnly />
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Thời gian chấm vào</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("time_checkin")} readOnly={user && (user.data.code === "MS000185" || user.data.code === "MS000192")  ? false : true} />
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Trạng thái chấm vào</label>
                                <select className="form-select height32" {...formik.getFieldProps("checkin_status")}>
                                    <option value="0">Không chấm</option>
                                    <option value="1">Đúng giờ</option>
                                    <option value="2">Vào muộn</option>
                                    <option value="3">Có phép</option>
                                    <option value="4">Đã giải trình</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Thời gian chấm ra</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("time_checkout")} readOnly={user && (user.data.code === "MS000185" || user.data.code === "MS000192") ? false : true} />
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Trạng thái chấm ra</label>
                                <select className="form-select height32" {...formik.getFieldProps("checkout_status")}>
                                    <option value="0">Không chấm</option>
                                    <option value="1">Đúng giờ</option>
                                    <option value="2">Ra sớm</option>
                                    <option value="3">Có phép</option>
                                    <option value="4">Đã giải trình</option>
                                </select>
                            </div>

                            <div className="col-12 col-sm-6 form-group">
                                <label>Trạng thái công</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("text_status")} />
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Số công</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("point")} />
                            </div>

                            <div className="col-12 form-group mt-3">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
