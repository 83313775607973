
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AkkhorLayout from "../layout/akkhor";
import axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useSelector } from "react-redux";
import parse from 'html-react-parser'

export default function XemTin() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [detail, setDetail] = useState(null)
    const [listFile, setListFile] = useState([])

    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                axios.get(URL_LIST_ALL + "news/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let data = res.data.data
                                if (data.filelist) setListFile(JSON.parse(data.filelist))
                                setDetail(data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id]
    );
    return (<AkkhorLayout idrole="dashboard">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Chi tiết
                </li>
            </ul>
        </div>

        {detail &&
            <div className="col-12 bg-white-border pd-15">
                <h3 className="new-title">{detail.title}</h3>
                <div className="w-100 news-content mt-3">
                    {detail && detail.content && parse(detail.content)}
                </div>
                {detail.files &&
                    <div className="row img-wrapper file-dinh-kem mt-5">
                        <h5 className="col-12">File đính kèm</h5>
                        <a href={detail.files} className="col-auto m-2">
                            {detail.file_type ?
                                <>
                                    {(detail.file_type.toLowerCase() === 'png' || detail.file_type.toLowerCase() === 'jpg' || detail.file_type.toLowerCase() === 'jpeg') && <img src={detail.files} className="w-100" alt=""/>}
                                    {(detail.file_type.toLowerCase() === 'word' || detail.file_type.toLowerCase() === 'doc' || detail.file_type.toLowerCase() === 'docx') && <img src="/icon-word.png" width="100px" alt=""/>}
                                    {detail.file_type.toLowerCase() === 'pdf' && <img src="/icon-pdf.png" width="100px" alt=""/>}
                                    {(detail.file_type.toLowerCase() === 'csv' || detail.file_type.toLowerCase() === 'xls' || detail.file_type.toLowerCase() === 'xlsx') && <img src="/icon-excel.png" width="100px" alt=""/>}
                                </>
                                :
                                "Tải file"
                            }
                            <span className="fas fa-download"></span>
                        </a>
                    </div>
                }
                {listFile &&
                    <div className="row img-wrapper file-dinh-kem-2 mt-5">
                        <h5 className="col-12">Các mẫu văn bản, tài liệu kèm theo</h5>
                        {listFile.map((item, i) => {
                            return <div className="col-12 col-sm-8 item-image mt-2 mb-2">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-4 col-sm-auto">
                                        <a href={item.value} className="w-100" key={i}>
                                            {item.filetype ?
                                                <>
                                                    {(item.filetype.toLowerCase() === 'png' || item.filetype.toLowerCase() === 'jpg' || item.filetype.toLowerCase() === 'jpeg') && <img src={item.value} alt=""/>}
                                                    {(item.filetype.toLowerCase() === 'word' || item.filetype.toLowerCase() === 'doc' || item.filetype.toLowerCase() === 'docx') && <img src="/icon-word.png" alt=""/>}
                                                    {item.filetype.toLowerCase() === 'pdf' && <img src="/icon-pdf.png" alt=""/>}
                                                    {(item.filetype.toLowerCase() === 'csv' || item.filetype.toLowerCase() === 'xls' || item.filetype.toLowerCase() === 'xlsx') && <img src="/icon-excel.png" alt=""/>}
                                                </>
                                                :
                                                "Tải file"
                                            }
                                        </a>
                                    </div>
                                    <div className="col-sm-6 col-6"><a href={item.value} className="w-100" key={i}><p><b>{item.title}</b></p></a></div>
                                    <div className="col-sm-2 col-2">
                                        <a href={item.value} className="w-100" key={i}><span className="fas fa-download"></span></a>
                                    </div>
                                </div>

                            </div>
                        })}
                    </div>
                }
            </div>
        }

    </AkkhorLayout>);
}
