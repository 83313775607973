import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import Select from 'react-select'
export default function UserDropDownClass(props) {
    const { apiname, data, placeholder, formik, name, onChange, colvalue, colname, defaultValue, disabled } = props;
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    const [list, setList] = useState(data ? data : []);


    const currenOnchange = async (e) => {
        if (formik) {
            await formik.setFieldValue(name, e)
            await formik.setFieldTouched(name)
        }
    }
    useEffect(() => {
        setValue(formik ? formik.values[name] : defaultValue)
    }, [formik, name, defaultValue])
    //Lấy danh sách dữ liệu
    useEffect(() => {
        let mounted = true;
        if (!data) {
            if (mounted) setLoading(true);
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'asselect': JSON.stringify([colvalue + " as value", colname + " as label"])
                }
            }
            Axios.get(URL_LIST_ALL + apiname + '?limit=5000', config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) {
                        setList(res.data.data);
                    }
                } else console.log(res.data.message);
            }).catch((err) => { console.log(err) });
        }
        return () => (mounted = false);
    }, [user, apiname, data, colvalue, colname]);

    return <Select className="w-100 select-multiple" disabled={disabled || loading} value={value} options={list} isMulti placeholder={placeholder} onChange={onChange ? onChange : currenOnchange} />;
}
//https://github.com/elrumordelaluz/reactour