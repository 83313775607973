import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";
import MyEditor2 from "../component/MyEditor2";
import SerchBoxMulti from "../component/SerchBoxMulti";
import UploadModal from "../component/UploadModal";

export default function FormTinTuc() {
    const { user } = useSelector((state) => state.auth);

    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [listFile,setListFile] = useState([])

    const defaultdetail = {
        author_id: user.data.id, title: '', status: 1,
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "news/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let data = res.data.data
                                if(data.forcompany) data.forcompany = JSON.parse(data.forcompany)
                                if(data.forparts) data.forparts = JSON.parse(data.forparts)
                                if(data.forusers) data.forusers = JSON.parse(data.forusers)
                                if(data.filelist) setListFile(JSON.parse(data.filelist))
                                setDetail(data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên danh mục').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if(listFile) values.filelist = JSON.stringify(listFile)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'news/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật tin thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'news', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới tin thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const actionListFile = (data) => {
        let newData = listFile.slice();
        data.forEach(item => {
            let filename = item.split('/')
            let filetype = item.split('.').pop()
            newData.push({title:"",value:item,filename:filename.pop(),filetype:filetype})
        });
        setListFile(newData);
    }

    const setTitleFile = (id,value) =>{
       let newList = listFile.slice()
       newList[id].title = value
       setListFile(newList)
    }

    const deleteItem = (id) => {
        let newList = []
        listFile.forEach((item,i)=>{
            if(i!==id) newList.push(item)
        })
        setListFile(newList)
        console.log(newList);
    }

    return (
        <AkkhorLayout idrole="danh-muc-tin">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/danh-muc-tin">Quản lý danh mục</Link>
                    </li>
                    <li>{id ? 'Sửa danh mục' : 'Thêm mới danh mục'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Quy định</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-lg-8 col-12 form-group">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            <div className="col-lg-4 col-12 form-group">
                                <label>Danh mục </label>
                                <SearchBoxModal apiname={"news_category"} placeholder="Chọn danh mục" colvalue={'id'} colname={'title'} defaultValue={formik.values['category_id']} name='category_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('category_id', e)
                                    }}></SearchBoxModal>

                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-12 form-group">
                                <label>Nội dung <span className="text-danger">*</span></label>
                                <MyEditor2 height={350} key={Math.random()} initialValue={formik.values.content}
                                    onValue={(value) => value && formik.setFieldValue("content", value)}
                                />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-lg-8 col-12 form-group">
                                <label>Tệp quy trình - thông báo</label>
                                <input type="text" placeholder="" className="form-control height32 mb-2" {...formik.getFieldProps("files")} autoComplete="off" disabled/>
                                <UploadModal defaultValue="" button_title="Tải lên" onSellect={(val) => {formik.setFieldValue('files',val)}} />
                            </div>
                            <div className="col-lg-8 col-12 form-group">
                                <label><b>Các mẫu văn bản, tài liệu kèm theo</b></label>
                                <UploadModal defaultValue="" multiple="true" button_title="Tải tệp đính kèm" onSellect={(val) => {actionListFile(val)}} />
                                <div className="row list-file mt-3">
                                    {listFile && listFile.map(function (item,i) {
                                        return <div className={"col-12 itemFile item-"+i} key={i}>
                                            <div className="row">
                                                <div className="col-4 form-group">
                                                    <input type="text" placeholder="Tiêu đề" className="form-control height32"  value={item.title ? item.title : ''} onChange={(e)=>setTitleFile(i,e.target.value)} autoComplete="off" />
                                                </div>
                                                <div className="col-6 form-group">
                                                    <input type="text" className="form-control height32 mb-2" value={item.filename ? item.filename : ''} placeholder="Đường dẫn"/>
                                                </div>
                                                <div className="col-2 form-group">
                                                    <button type="button" className="btn btn-sm btn-danger" onClick={()=>deleteItem(i)}>Xóa</button>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                               
                            </div>
                            <div className="col-xs-12"><hr></hr></div>
                            <div className="col-xs-12 mt-3">
                                <strong>Những ai có thể xem? </strong>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-12 form-group">
                                <label>Công ty: </label>
                                <SerchBoxMulti apiname={"company"} placeholder="Chọn công ty" colvalue={'id'} colname={'title'} defaultValue={formik.values['forcompany']} name='forcompany'
                                    onChange={async (e) => {
                                        console.log(e);
                                        await formik.setFieldValue('forcompany', e)
                                    }}></SerchBoxMulti>

                            </div>
                            <div className="col-12 form-group">
                                <label>Phòng ban: </label>
                                <SerchBoxMulti apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} defaultValue={formik.values['forparts']} name='forparts'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('forparts', e)
                                    }}></SerchBoxMulti>

                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-12 form-group">
                                <label>Cá nhân: </label>
                                <SerchBoxMulti apiname={"users"} placeholder="Chọn nhân sự" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['forusers']} name='forusers'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('forusers', e)
                                    }}></SerchBoxMulti>

                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-lg-4 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
