
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function ListSettingsPage() {
  const [params, setParam] = useState();
  const [keyword, setKeyword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (keyword !== '') _param += "&key=" + keyword
    setParam(_param);
  };



  let columns = [
    { name: "id", label: "ID" },
    { name: "title", label: "Tên cài đặt" },
    { name: "alias", label: "Tên rút gọn" },
    { name: "status", label: "Trạng thái" },
    { name: "datecreate", label: "Ngày tạo"}
    
  ];

  return (<AkkhorLayout idrole="list-settings">
    <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Cài đặt thông số
                </li>
                
            </ul>
        </div>
    <SRList name="settings" idrole="list-settings" params={params} title="Tài khoản" defaultLimit={10} serverSide={true} columns={columns} >
      <div className="card mb-3">
          <div className="card-body ">
            <div className="d-flex pb-2">
              <div className="mr-auto">
                <h3>Tìm kiếm thông số cài đặt</h3>
              </div>
            </div>
            <form className="new-added-form" method="get" >
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
                  <label>Từ khoá </label>
                  <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
                
                <div className="col-12 ">
                  <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
                </div>
              </div>
            </form>
          </div>
        </div>
    </SRList>
  </AkkhorLayout>);
}
