
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { Link } from "react-router-dom";
import BangChotCongTable from "../component/BangChotCongTable";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";

export default function BangChotCongThang() {
  const { user } = useSelector((state) => state.auth);
  const [params, setParam] = useState('');
  const [code, setCode] = useState('');
  const [fullname, setFullname] = useState('');
  const [filterShow, setFilterShow] = useState(false)
  const [month, setMonth] = useState([])
  const [year, setYear] = useState([])
  const [fMonth, setFMonth] = useState()
  const [fYear, setFYear] = useState()
  const [linkDown, setLinkDown] = useState(null)

  let columns = [
    { name: "fullname", label: "Họ và tên" },
    { name: "code", label: "MSNV" },
    { name: "part", label: "Phòng/ban" },
    { name: "company", label: "Công ty" },
    { name: "congchuan", label: "Công chuẩn" },
    { name: "conghl", label: "HL" },
    { name: "congtt", label: "Thực tế" },
    { name: "congnvp", label: "Công NVP" },
    { name: "congct", label: "Công tác" },
    { name: "nghiphep", label: "Nghỉ phép" },
    { name: "nghibu", label: "Nghỉ bù" },
    { name: "nghile", label: "Nghỉ lễ" },
    { name: "nghichedo", label: "Chế độ" },
    { name: "nghiom", label: "Nghỉ ốm" },
    { name: "nghikl", label: "KL" },
    { name: "sldmvs1", label: "M/S (<10')" },
    { name: "sldmvs2", label: "M/S (10-30')" },
    { name: "sldmvs3", label: "M/S (>30')" },
    { name: "slkcc", label: "KV/KR" },
    { name: "slkgt", label: "KGT" },
    { name: "slnkp", label: "KP" },
    { name: "slkpd", label: "KD" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (fullname !== '') _param += "&fullname=" + fullname
    if (code !== '') _param += "&code=" + code
    if (fYear && fMonth && fMonth !== '' && fYear !== '') _param += "&time=" + fYear + '-' + fMonth
    console.log(_param);
    setParam(_param);
  };

  useEffect(function () {
    let _month = []
    for (let m = 1; m <= 12; m++) {
      _month.push(m)
    }
    setMonth(_month)

    let _year = []
    let maxYear = new Date().getFullYear()
    for (let y = 2021; y <= maxYear + 1; y++) {
      _year.push(y)
    }
    setYear(_year)
  }, []);

  const chotBaoCao = () => {
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn chốt báo cáo và gửi email cho tất cả nhân sự ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          Axios.get(URL_LIST_ALL + 'chotbaocao?chot=1' + params, user.config).then(res => {
            if (res.data.status === "success") {
              alert("Chốt thành công");
              window.location.href = res.data.data
              setLinkDown(res.data.data)
            } else alert(res.data.message);
          }).catch(err => { console.log(err); });
        },
      }, { label: "Không" },
      ],
    });
  }

  return (<AkkhorLayout idrole="bangchotcongthang">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Bảng chốt công tháng
        </li>

      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên nhân viên </label>
              <input type="text" placeholder="Tìm theo tên" value={fullname} onChange={(e) => setFullname(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 form-group">
              <label>Mã nhân viên </label>
              <input type="text" placeholder="Tìm theo mã nhân viên" value={code} onChange={(e) => setCode(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>

            <div className="col-12 col-sm-4 form-group">
              <div className="row">
                <div className="col-lg-6 col-12 form-group">
                  <label>Chọn tháng</label>
                  <select className="form-select height32" onChange={(e) => { setFMonth(e.target.value) }}>
                    {month && month.map((item, i) => {
                      return <option key={i} value={item < 10 ? '0' + item.toString() : item}>Tháng {item}</option>
                    })}
                  </select>
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <label>Chọn năm</label>
                  <select className="form-select height32" onChange={(e) => { setFYear(e.target.value) }}>
                    {year && year.map((item, i) => {
                      return <option key={i} value={item < 10 ? '0' + item.toString() : item}>{item}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="w-100 d-flex justify-content-center">
      <button type="button" className="btn btn-sm btn-success" onClick={() => chotBaoCao()}>Chốt báo cáo và gửi mail cho nhân sự</button>
    </div>
    {linkDown &&
      <div className="w-100 text-center">
        <p>Đã load xong vùi lòng chọn tải về</p>
        <a className="btn btn-danger" href={linkDown}>Tải về</a>
      </div>
    }
    <div className="col-12 mt-3 mb-3">
      <div className="font-13">
        <span>HL: Công hưởng lương; </span>
        <span>KL: Nghỉ không lương; </span>
        <span>M/S: Số lần đi muộn về sớm; </span>
        <span>NVP: Làm ngoài văn phòng; </span>
        <span>KV/KR: Không chấm công vào/ra; </span>
        <span>KGT: Không giải trình; </span>
        <span>KP: Không phép; </span>
        <span>KD: Không duyệt giải trình; </span>
      </div>
    </div>
    <BangChotCongTable name="baocaochamcong" idrole="bangchotcongthang" params={params} title="Bảng chốt công tháng" defaultLimit={50} serverSide={true} columns={columns} >
    </BangChotCongTable>
  </AkkhorLayout>);
}
