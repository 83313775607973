export const checkRole = (user, idrole, action) => {
    try {
        if (action && action !== '') {
            const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
            if (MyRole.hasOwnProperty(idrole) && MyRole[idrole].checked === true) {
                if (MyRole[idrole].hasOwnProperty('children') && MyRole[idrole].children.hasOwnProperty(action) && MyRole[idrole].children[action].checked === true)
                    return true;
            }
        }else{
            const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
            if (MyRole.hasOwnProperty(idrole) && MyRole[idrole].checked === true) {
                return true;
            }
        }

    } catch (err) {
        return false;
    }
    return false;
}