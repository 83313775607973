
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function DanhSachNgayLe() {
  const [params, setParam] = useState();
  const [keyword, setKeyword] = useState('');
  const [filterShow, setFilterShow] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (keyword !== '') _param += "&key=" + keyword
    setParam(_param);
  };
  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên ngày lễ", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "datestart",label: "Ngày bắt đầu"},
    { name: "dateend",label: "Ngày kết thúc"},
    { name: "datecreate",label: "Ngày tạo"}
  ];


  return (<AkkhorLayout idrole="ngay-le">
    <div className="breadcrumbs-area">
        <ul>
            <li>
                <Link to="/">Trang chủ</Link>
            </li>
            
            <li>Danh sách ngày lễ trong năm</li>
        </ul>
    </div>
    <div className="card mb-3">
      <div className="card-header">
        <div className="d-flex">
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={()=>setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>Từ khoá </label>
              <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="nghile" linkaction="ngay-le" idrole="ngay-le" params={params} title="Nghỉ lễ" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
  </AkkhorLayout>);
}
