
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import SearchBoxModal from "../component/SearchBoxModal";

export default function QuanLyCa() {
  const [params, setParam] = useState('');
  const [title, setTitle] = useState();
  const [partID, setPartID] = useState();
  const [filterShow, setFilterShow] = useState(false)
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (title !== '') _param += "&title=" + title
    if (partID !== '') _param += "&part_id=" + partID
    setParam(_param);
  };


  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên ca" },
    { name: "timestart", label: "Thời gian bắt đầu ca" },
    { name: "reststart", label: "Bắt đầu nghỉ giữa ca" },
    { name: "restend", label: "Kết thúc nghỉ giữa ca" },
    { name: "timeend", label: "Thời gian kết thúc ca" },
    { name: "status", label: "Trạng thái" },
    { name: "author", label: "Người tạo" },
    { name: "datecreate", label: "Ngày tạo" },
    { name: "datemodified", label: "Ngày sửa" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
        <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              Danh sách ca làm việc
            </li>
        </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={()=>setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={()=>setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
                <label>Tên ca </label>
                <input type="text" placeholder="Tên ca" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Phòng ban </label>
                <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} name='part_id' defaultValue={partID}
                    onChange={ (e) => {setPartID(e)}}></SearchBoxModal>
            </div>
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="quanlyca" linkaction="quan-ly-ca" idrole="quan-ly-ca" params={params} defaultLimit={50} serverSide={true} columns={columns} >

    </SRList>
  </AkkhorLayout>);
}
