import React, { useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';

export default function MyEditor2(props) {
  const editorRef = useRef(null);
  const { height, initialValue, onValue } = props;
  const [value, setValue] = useState(initialValue);

  return (<>
    <Editor apiKey="vswj07wyg97x3fp9hrymkie5h0ixrlu72ps7m75rr9rxr6qh"
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      onFocusOut={() => {
        const valuecontent = editorRef.current.getContent()
        if (valuecontent && valuecontent !== initialValue) {
          onValue(editorRef.current.getContent())
        }
      }}
      onEditorChange={(newValue, editor) => {
        setValue(newValue);
      }}
      init={{
        height: height ? height : 500,
        menubar: false,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | image | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  </>
  );
}
