import React, { useState } from "react";
import { useFormik } from "formik";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import * as Yup from "yup";
import DateTimePicker from "react-datetime-picker";
import AlertErrorForm from "../component/AlertErrorForm";
import moment from "moment";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";

export default function Registration() {
  const [loading, setLoading] = useState(false)
  const [dateSelect, setDateSelect] = useState({
    birthday: null, cccd_date: null, namsinhcon_1: null, namsinhcon_2: null, namsinhcon_3: null, datejoining: null
  });
  const defaultdetail = {
    fullname: '',
    email: '',
    mobile: '',
    sex: '',
    chieucao: '',
    cannang: '',
    birthday: '',
    cccd: '',
    vitriungtuyen: '',
    tenphongban: '',
  }
  const formik = useFormik({
    initialValues: defaultdetail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      fullname: Yup.string().trim().nullable().required('Nhập họ tên').max(150, "Độ dài không quá 150 ký tự"),
      email: Yup.string().trim().nullable().required('Nhập email').max(150, "Độ dài không quá 150 ký tự"),
      mobile: Yup.string().trim().nullable().required('Nhập số điện thoại').max(15, "Độ dài không quá 15 ký tự"),
      birthday: Yup.string().trim().nullable().required('Nhập ngày sinh'),
      cccd: Yup.string().trim().nullable().required('Nhập Số cccd/cmnd'),
      sex: Yup.string().trim().nullable().required('Nhập giới tính'),
      vitriungtuyen: Yup.string().trim().nullable().required('Nhập vị trí ứng tuyển'),
      tenphongban: Yup.string().trim().nullable().required('Nhập tên phòng ban muốn ứng tuyển'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true)
      Axios.post(URL_LIST_ALL + 'registry', values).then(res => {
        setLoading(false);
        setSubmitting(false);
        if (res.data.status === "success") {
          confirmAlert({
            title: "Thông báo",
            message: "Cảm ơn bạn đã bổ sung thông tin. Vui lòng đợi phòng nhân sự phê duyệt thông tin.",
            buttons: [{
              label: "Có",
              onClick: () => {
                resetForm()
              },
            }, { label: "Không" },
            ],
          });
        }
      }).catch(err => console.log(err));

    },
  });

  const handleDate = (name, value) => {
    formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
    formik.setFieldTouched(name)
    let newDate = Object.assign({}, dateSelect)
    newDate[name] = value
    setDateSelect(newDate)
  };

  return <div className="layout-login h-100">
    <div className="container btn-login-gr pt-5">
      <div className="mb-3 w-100 d-flex justify-content-center">
        <img src="https://bhsgroup.vn/wp-content/uploads/2021/12/Asset-14@4x.png" width={100} alt="upload" />
      </div>
      <div className="container">
        <form className="w-100 bg-white new-added-form rounded shaddow login100-form validate-form p-5" method="post" onSubmit={formik.handleSubmit}>
          <div className="row">
            <h3 className="w-100 text-center mb-5">Bổ sung thông tin cá nhân</h3>
            <div className="col-12 col-sm-4 form-group">
              <label>Họ và tên <span className="text-danger">*</span></label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
              <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Điện thoại <span className="text-danger">*</span></label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
              <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Email <span className="text-danger">*</span></label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
              <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Link facebook</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("linkfacebook")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Ngày sinh <span className="text-danger">*</span></label>
              <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
              <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Địa chỉ liên lạc</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Giới tính <span className="text-danger">*</span></label>
              <select className="form-select height32" {...formik.getFieldProps("sex")}>
                <option value="" disabled>Hãy chọn giới tính</option>
                <option value="Nam">Nam</option>
                <option value="Nữ">Nữ</option>
                <option value="Khác">Khác</option>
              </select>
              <AlertErrorForm formik={formik} name="sex"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Số CCCD/CMND: <span className="text-danger">*</span></label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd")} />
              <AlertErrorForm formik={formik} name="cccd"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Ngày cấp CCCD/CMND: </label>
              <DateTimePicker onChange={(value) => { handleDate('cccd_date', value) }} value={dateSelect.cccd_date ? new Date(dateSelect.cccd_date) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Nơi cấp CCCD/CMND: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd_address")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Địa chỉ thường trú: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address_tc")} />
            </div>
            <div className="col-12"></div>
            <div className="col-12 col-sm-4 form-group">
              <label>Chiều cao (cm)</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("chieucao")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Cân nặng (kg)</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cannang")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Sở thích</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("sothich")} />
            </div>
            <div className="col-12 col-sm-3 form-group">
              <label>Mã số thuế</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mst_id")} />
            </div>
            <div className="col-12 col-sm-3 form-group">
              <label>Số sổ bhxh</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bhxh_id")} />
            </div>
            <div className="col-12 col-sm-3 form-group">
              <label>Người quen làm việc tại BHS ?</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nguoiquen")} />
            </div>
            <div className="col-12 col-sm-3 form-group">
              <label>Tên người quen</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tennguoiquen")} />
            </div>
            <div className="col-12"></div>
            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label>Số TK ngân hàng techcombank:	</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_id")} />
            </div>
            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label>Chi nhánh:	</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_address")} />
            </div>
            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label>Số TK ngân hàng BIDV:	</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_2_id")} />
            </div>
            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label>Chi nhánh:	</label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_2_address")} />
            </div>
           
            <div className="col-12"></div>
            <div className="col-12 col-sm-4 form-group">
              <label>Vị trí ứng tuyển <span className="text-danger">*</span></label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("vitriungtuyen")} />
              <AlertErrorForm formik={formik} name="vitriungtuyen"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Thuộc phòng ban <span className="text-danger">*</span></label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tenphongban")} />
              <AlertErrorForm formik={formik} name="tenphongban"></AlertErrorForm>
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Ngày bắt đầu làm việc: </label>
              <DateTimePicker onChange={(value) => { handleDate('datejoining', value) }} value={dateSelect.datejoining ? new Date(dateSelect.datejoining) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <h3 className="col-12 mb-2">Học vấn</h3>
            <div className="col-12 col-sm-4 form-group">
              <label>Tên trường cấp 1: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("truongcap_1")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Năm tốt nghiệp: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_1")} />
            </div>
            <div className="col-12"></div>
            <div className="col-12 col-sm-4 form-group">
              <label>Tên trường cấp 2: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("truongcap_2")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Năm tốt nghiệp: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_2")} />
            </div>
            <div className="col-12"></div>
            <div className="col-12 col-sm-4 form-group">
              <label>Tên trường cấp 3: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("truongcap_3")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Năm tốt nghiệp: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_3")} />
            </div>
            <div className="col-12"></div>
            <div className="col-12 col-sm-4 form-group">
              <label>Tên trường đại học/cao đẳng: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("daihoc")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Chuyên ngành đào tạo: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("chuyennganh")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Năm tốt nghiệp: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_4")} />
            </div>
            <div className="col-12"></div>
            <div className="col-12">
              <hr />
            </div>
            <h3 className="col-12 mb-2">Gia đình</h3>
            <div className="col-12 col-sm-4 form-group">
              <label>Tình trạng hôn nhân</label>
              <select className="form-select height32" {...formik.getFieldProps("marital_status")}>
                <option value="Chưa kết hôn" >Chưa kết hôn</option>
                <option value="Đã kết hôn" >Đã kết hôn</option>
                <option value="Đã ly hôn" >Đã ly hôn</option>
              </select>
            </div>
            <div className="col-12"></div>

            {((formik.values.marital_status && formik.values.marital_status === "Đã kết hôn") || (formik.values.marital_status && formik.values.marital_status === "Đã ly hôn")) &&
              <>
                {formik.values.marital_status === "Đã kết hôn" &&
                  <>
                    <div className="col-12 col-sm-4 form-group">
                      <label>Họ Tên vợ/chồng: </label>
                      <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_vo_chong")} />
                    </div>
                    <div className="col-12 col-sm-4 form-group">
                      <label>Năm sinh: </label>
                      <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_vo_chong")} />
                    </div>
                    <div className="col-12 col-sm-4 form-group">
                      <label>Số điện thoại vợ/chồng: </label>
                      <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("phone_love")} />
                    </div>
                  </>
                }
                <div className="col-12 col-sm-3 form-group">
                  <label>Tên con 1: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_1")} />
                </div>
                <div className="col-12 col-sm-3 form-group">
                  <label>Sinh nhật con 1: </label>
                  <DateTimePicker onChange={(value) => { handleDate('namsinhcon_1', value) }} value={dateSelect.namsinhcon_1 ? new Date(dateSelect.namsinhcon_1) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                </div>

                <div className="col-12 col-sm-3 form-group">
                  <label>Tên con 2: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_2")} />
                </div>
                <div className="col-12 col-sm-3 form-group">
                  <label>Sinh nhật con 2: </label>
                  <DateTimePicker onChange={(value) => { handleDate('namsinhcon_2', value) }} value={dateSelect.namsinhcon_2 ? new Date(dateSelect.namsinhcon_2) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                </div>

                <div className="col-12 col-sm-3 form-group">
                  <label>Tên con 3: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_3")} />
                </div>
                <div className="col-12 col-sm-3 form-group">
                  <label>Sinh nhật con 3: </label>
                  <DateTimePicker onChange={(value) => { handleDate('namsinhcon_3', value) }} value={dateSelect.namsinhcon_3 ? new Date(dateSelect.namsinhcon_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                </div>

                <div className="col-12 col-sm-3 form-group">
                  <label>Tên con 4: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_3")} />
                </div>
                <div className="col-12 col-sm-3 form-group">
                  <label>Sinh nhật con 4: </label>
                  <DateTimePicker onChange={(value) => { handleDate('namsinhcon_3', value) }} value={dateSelect.namsinhcon_3 ? new Date(dateSelect.namsinhcon_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                </div>
              </>
            }
            <div className="col-12"></div>
            <div className="col-12 col-sm-4 form-group">
              <label>Họ tên bố đẻ: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_bo")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Năm sinh: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_bo")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Nghề nghiệp: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepbo")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Họ tên mẹ đẻ: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_me")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Năm sinh: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_me")} />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>Nghề nghiệp: </label>
              <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepme")} />
            </div>
            {(formik.values.marital_status && formik.values.marital_status === "Đã kết hôn") ?
              <>
                <div className="col-12 col-sm-4 form-group">
                  <label>Họ tên bố vợ/chồng: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_bo_2")} />
                </div>
                <div className="col-12 col-sm-4 form-group">
                  <label>Năm sinh: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_bo_2")} />
                </div>
                <div className="col-12 col-sm-4 form-group">
                  <label>Nghề nghiệp: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepbo_2")} />
                </div>
                <div className="col-12 col-sm-4 form-group">
                  <label>Họ tên mẹ vợ/chồng: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_me_2")} />
                </div>
                <div className="col-12 col-sm-4 form-group">
                  <label>Năm sinh: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_me_2")} />
                </div>
                <div className="col-12 col-sm-4 form-group">
                  <label>Nghề nghiệp: </label>
                  <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepme_2")} />
                </div>

              </>
              : ''
            }
            <div className="col-12 d-flex justify-content-center form-group mt-5">
              <button type="submit" disabled={formik.isSubmitting} className="btn pl-5 pr-5 btn-success text-white">{loading ? <Spinner /> : <>Lưu thông tin</>}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>;
}
