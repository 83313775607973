import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import configData from "../configs/config.json";
import "../../src/assets/css/login.css";

export default function LoginSSO() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({ isAuthorized: auth.user != null }),
        shallowEqual
    );
    const successGoogle = (response) => {
        console.log(response);
        // Axios.post(URL_LIST_ALL + "login", response).then((res) => {
        //     if (res.data.status === "success") {
        //         let user = res.data;
        //         user.config = createConfig(res.data.access_token);
        //         dispatch(actions.login(user));
        //     } else alert(res.data.message)
        // }).catch((err) => console.log(err));
        window.addEventListener("message", {data:response});
    }

    return (
        <div>
            {isAuthorized ? (
                <Redirect to="/" />
            ) : (
                <div className="layout-login">
                    <div className="text-center">
                        <GoogleLogin
                            clientId={configData.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Đăng nhập tài khoản Google"
                            onSuccess={successGoogle}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
