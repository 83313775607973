
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormTimeSettings() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const defaultdetail = {
        author_id: user.data.id, title: '', company_id: '', time_start: '', type: "Chấm vào", level: 1, comment: ''
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users_onday_settings/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Vui lòng nhập tiêu đề'),
            time_start: Yup.string().trim().nullable().required('Chọn thời gian'),
            company_id: Yup.string().trim().nullable().required('Chọn công ty'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'users_onday_settings/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật cài đặt thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'users_onday_settings', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới cài đặt thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="list-users_onday_settings">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-users_onday_settings">Danh sách cài đặt chấm công</Link>
                    </li>
                    <li>{id ? 'Cập nhập cài đặt chấm công' : 'Thêm mới cài đặt chấm công'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Cài đặt thời gian chấm công</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-6 col-12">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label>Tiêu đề <span className="text-danger">*</span></label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} />
                                        <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                                    </div>

                                    <div className="col-12 form-group">
                                        <label>Công ty <span className="text-danger">*</span></label>
                                        <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" all={true} colvalue={'id'} colname={'title'} defaultValue={formik.values['company_id']} name='company_id'
                                            onChange={async (e) => {
                                                await formik.setFieldValue('company_id', e)
                                            }}></SearchBoxModal>
                                        <AlertErrorForm formik={formik} name="company_id"></AlertErrorForm>
                                    </div>


                                    <div className="col-12 form-group">
                                        <label>Thời gian <span className="text-danger">*</span></label>
                                        <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_start")} />
                                        <AlertErrorForm formik={formik} name="time_start"></AlertErrorForm>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label>Nội dung</label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("comment")} />
                                    </div>

                                    <div className="col-12 form-group">
                                        <label>Phân loại</label>
                                        <select className="form-select height32" {...formik.getFieldProps("type")}>
                                            <option value="Chấm vào">Chấm vào</option>
                                            <option value="Chấm ra">Chấm ra</option>
                                        </select>
                                    </div>

                                    <div className="col-12 form-group">
                                        <label>Thứ tự</label>
                                        <select className="form-select height32" {...formik.getFieldProps("level")}>
                                            <option value="1">Lần 1</option>
                                            <option value="2">Lần 2</option>
                                            <option value="3">Lần 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 form-group mt-3">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
