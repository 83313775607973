import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UploadButton from "../component/UploadButton";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";
import file_icon from "../assets/img/file-icon.png"
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";

export default function FormVanThu() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const submitRef = useRef(null);
    const [vanThuParam, setVanThuParam] = useState([])
    const [listParts, setListParts] = useState(null)
    const [listCompany, setListCompany] = useState(null)
    const [listMember, setListMember] = useState(null)
    const [loadMember, setLoadMember] = useState(false)
    const [member, setMember] = useState(false)
    const [parentId, setParentId] = useState(false)
    const [show, setShow] = useState(false)
    const [idSelect, setIdSelect] = useState(null)

    const [dateSelect, setDateSelect] = useState({ dateend: null, dateyc: null, ngayky: null });

    const defaultdetail = {
        fullname: user ? user.data.fullname : '',
        part: user ? user.data.part_title : '',
        part_code: user ? user.data.part_code : '',
        company: user ? user.data.company_id : '',
        user_id: user ? user.data.id : '',
        user_code: user ? user.data.code : '',
        parent_id: 0,
        type: '',
        approve_id: '',
        date_start: '',
        ngayky: '',
        title: '',
        file_url: '',
        code: '',
        type_vt: '',
        hide_with_approve: null,
        number: '',
        status: 0
    }

    useEffect(function () {
        // console.log(role_edit);
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "vanthu/" + id, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    let data = res.data.data
                    Object.keys(data).map(key => {
                        if (!data[key]) {
                            data[key] = ''
                        }
                    })
                    if (data.file_url) {
                        data.file_url = JSON.parse(data.file_url)
                    }
                    setDetail(data);
                    if (data.member) {
                        setListMember(data.member)
                    }
                    setDateSelect({ ngayky: data.ngayky })
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        } else {
            Axios.get(URL_LIST_ALL + "users/" + user.data.id, user.config).then(async (res) => {
                if (res.data.status === "success") {
                    let info = res.data.data
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        Axios.get(URL_LIST_ALL + "vanthu_param?orderby=title-ASC&limit=1000&choice=1", user.config).then(async (res) => {
            if (res.data.status == "success") {
                setVanThuParam(res.data.data)
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
        //lấy danh sách thông tin các phòng ban
        Axios.get(URL_LIST_ALL + "parts?orderby=title-ASC&limit=1000&getvanthu=1", user.config).then(async (res) => {
            if (res.data.status == "success") {
                setListParts(res.data.data)
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
        Axios.get(URL_LIST_ALL + "company?orderby=title-ASC&limit=1000&getvanthu=1", user.config).then(async (res) => {
            if (res.data.status == "success") {
                setListCompany(res.data.data)
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
    }, [user, id, refresh]);// eslint-disable-line

    useEffect(function () {
        if (listParts && !detail) {
            let myPart = findPart(user.data.part_id)
            formik.setFieldValue('part_code', myPart.shortname)
        }
    }, [listParts, detail]);// eslint-disable-line

    useEffect(function () {
        if (parentId) {
            Axios.get(URL_LIST_ALL + "vanthu/" + parentId, user.config).then(async (res) => {
                if (res.data.status == "success") {
                    formik.setFieldValue('approve_id', res.data.data.approve_id)
                    formik.setFieldValue('approve_name', res.data.data.approve_name)
                    formik.setFieldValue('type_vt', res.data.data.type_vt)
                    formik.setFieldValue('code', formik.values['title'] + '-' + res.data.data.code)
                    formik.setFieldValue('code_file', formik.values['title'] + '-' + res.data.data.code)
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
    }, [parentId]);// eslint-disable-line

    useEffect(function () {
        if (listCompany && !detail) {
            let myCompany = findCompany(user.data.company_id)
            formik.setFieldValue('company_code', myCompany.vanthu_code)
        }
    }, [listCompany, detail]);// eslint-disable-line

    useEffect(function () {
        if (id) {
            Axios.get(URL_LIST_ALL + 'vanthu_member?limit=5000&vanthu_id=' + id, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    setListMember(res.data.data)
                } else console.log(res.data.message);
            }).catch((err) => { console.log(err) });
        }
    }, [loadMember, detail]);// eslint-disable-line

    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Vui lòng nhập tiêu đề'),
            type_vt: Yup.string().trim().nullable().required('Vui lòng chọn trạng thái văn thư'),
            type: Yup.string().trim().nullable().required('Vui lòng chọn loại văn thư'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                if (values.status == 2 && values.file_url) {
                    values.status = 3
                }
            } else {
                if (values.approve_id == user.data.id) {
                    values.status = 2;
                } else {
                    values.status = 1;
                }
            }
            if (!values.parent_id) {
                values.parent_id = 0;
            }
            if (id > 0) {
                if (detail.resion && detail.resion != values.resion) {
                    values.resion = user.data.fullname + ': ' + values.resion
                }
                if (detail.status == 5 && detail.user_id == user.data.id) {
                    values.status = 0
                    values.resion = null;
                }
                if (detail.status == 7 && detail.user_id == user.data.id) {
                    values.status = 3
                    values.resion = null;
                }
                if (detail.status == 6 && detail.user_id == user.data.id) {
                    values.status = 1
                    values.resion = null;
                }
                Axios.put(URL_LIST_ALL + 'vanthu/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        let message = '';
                        if (values.status == 0) {
                            message = 'Gửi lại thành công'
                        }
                        if (values.status == 1) {
                            message = 'Gửi lại thành công'
                        }
                        if (values.status == 2) {
                            message = 'Bạn đã cấp số thành công'
                        }
                        if (values.status == 3) {
                            message = 'Bạn đã gửi file thành công, vui lòng đợi Chấp thuận'
                        }
                        if (values.status == 4) {
                            message = 'Bạn đã hoàn tất chu trình cấp số văn thư'
                        }
                        if (values.status == 5) {
                            message = 'Bạn đã từ chối thành công, chu trình sẽ thông báo và gửi lại cho ' + values.fullname + '-' + values.user_code
                        }
                        if (values.status == 6) {
                            message = 'Bạn đã từ chối thành công, chu trình sẽ thông báo và gửi lại cho ' + values.fullname + '-' + values.user_code
                        }
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: message,
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'vanthu', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn đăng ký lấy số thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    if (values.approve_id == user.data.id) {
                                        window.location.reload()
                                    } else {
                                        window.location.href = "/van-thu"
                                    }

                                },
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleClick = useCallback(() => submitRef.current?.click(), []);

    useEffect(function () {
        if (formik.values.type && formik.values.type != '') {
            let vt_type = formik.values.type
            let itemcode = findFindParam(vt_type)
            if (itemcode) {
                if (listParts) {

                    if (itemcode.type_moderator == 'nguoixinso') {
                        formik.setFieldValue('approve_id', formik.values['user_id'])
                    }
                    if (itemcode.type_moderator == 'phc') {
                        let part_info = findPart(4)
                        formik.setFieldValue('approve_id', part_info.vanthu)
                    }
                    if (itemcode.type_moderator == 'pns') {
                        let part_info = findPart(3)
                        formik.setFieldValue('approve_id', part_info.vanthu)
                    }
                    if (itemcode.type_moderator == 'phongban') {
                        let part_info = findPart(itemcode.moderator)
                        formik.setFieldValue('approve_id', part_info.vanthu)
                    }
                    if (itemcode.type_moderator == 'ppc') {
                        let part_info = findPart(6)
                        formik.setFieldValue('approve_id', part_info.vanthu)
                    }
                }
            }
        }
    }, [formik && formik.values && formik.values.type ? formik.values.type : null]);

    useEffect(function () {
        try {
            if (formik.values.company && formik.values.company!='') {
                let cpn = findCompany(formik.values.company)
                formik.setFieldValue('company_code', cpn.company_code)
            }
        } catch (error) {
            
        }
        
    }, [formik && formik.values && formik.values.company ? formik.values.company : null]);

    const findFindParam = (id) => {
        return vanThuParam.find(item => item.id == id);
    };
    const findPart = (id) => {
        return listParts.find(item => item.id == id);
    };
    const findCompany = (id) => {
        return listCompany.find(item => item.id == id);
    };

    const addMember = () => {
        if (id && member) {
            Axios.post(URL_LIST_ALL + 'vanthu_member', { user_code: member, vanthu_id: id, dateend: dateSelect.dateend }, user.config).then(res => {
                setLoadMember(!loadMember)
            })
        }
    }

    const deleteMember = (memid) => {
        Axios.delete(URL_LIST_ALL + 'vanthu_member/' + memid, user.config).then(res => {
            setLoadMember(!loadMember)
        })
    }
    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    const yeuCauTiepCan = () => {
        Axios.post(URL_LIST_ALL + 'vanthu_tiepcan', { user_id: user.data.id, vanthu_id: detail.id, status: 0 }, user.config).then(res => {
            confirmAlert({
                title: 'Thành công',
                message: 'Bạn đã yêu cầu thành thành công',
                buttons: [{
                    label: 'OK', onClick: () => {
                        window.location.reload()
                    },
                }]
            });
        })
    }

    const tlYeucau = (id, status) => {
        Axios.put(URL_LIST_ALL + 'vanthu_tiepcan/' + id, { status: status, dateend: dateSelect.dateyc ? moment(dateSelect.dateyc).format('YYYY-MM-DD') : null }, user.config).then(res => {
            confirmAlert({
                title: 'Thành công',
                message: 'Trả lời thành công',
                buttons: [{
                    label: 'OK', onClick: () => {
                        handleDate('dateend', null)
                        setIdSelect(null)
                        window.location.reload()
                    },
                }]
            });
        })
    }

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="card height-auto">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title w-100 d-flex justify-content-between">
                            <h4>
                                {id ? "Cập nhật/Chấp thuận văn thư" : "Đăng ký lấy số"}
                                {/* <button type="button" className="btn btn-sm btn-warning">Hướng dẫn</button> */}
                            </h4>
                            <div className="">
                                {detail && detail.status == 7 && <span className="text-danger"><i className="far fa-pause-circle"></i>PHC đã từ chối duyệt file scan</span>}
                                {detail && detail.status == 6 && <span className="text-danger"><i className="far fa-pause-circle"></i>PHC đã từ chối cấp số</span>}
                                {detail && detail.status == 5 && <span className="text-danger"><i className="fas fa-exclamation-circle"></i>Quản lý từ chối duyệt</span>}
                                {detail && detail.status == 4 && <span className="text-success"><i className="fab fa-centos"></i> Hoàn tất việc cấp số</span>}
                                {detail && detail.status == 3 && <span className="text-success"><i className="fab fa-centos"></i> Đã gửi file chờ xác nhận</span>}
                                {detail && detail.status == 2 && <span className="text-success"><i className="fab fa-centos"></i> Đã cấp số chờ gửi file Scan</span>}
                                {detail && detail.status == 1 && <span className="text-success"><i className="fab fa-centos"></i> Chờ cấp số</span>}
                                {detail && detail.status == 0 && <span className="text-warning"><i className="fab fa-stumbleupon-circle"></i> Đã gửi chờ Chấp thuận</span>}
                            </div>
                        </div>
                    </div>

                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 form-group form-group">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" className="form-control height32" autoComplete="off" {...formik.getFieldProps("title")} placeholder="Ví dụ: Ban hành hệ thống chức danh"/>
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Họ và tên</label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Mã nhân viên</label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("user_code")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Phòng ban </label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("part")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Mã phòng ban </label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("part_code")} autoComplete="off" />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Công ty </label>
                                <SearchBoxModal apiname={"company"} placeholder="Chọn văn thư" colvalue={'id'} colname={'title'} defaultValue={formik.values['company']} name='company' disabled={detail && user.data.id == detail.user_id && (detail.status == 1 || detail.status == 3 || detail.status == 4 || detail.status == 2) ? true : false}
                                    onChange={async (e) => {
                                        await formik.setFieldValue('company', e)
                                        let company_info = findCompany(e)
                                        if (company_info) {
                                            await formik.setFieldValue('company_code', company_info.vanthu_code)
                                        }

                                    }}></SearchBoxModal>
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Mã công ty </label>
                                <input type="text" placeholder="" readOnly className="form-control height32" {...formik.getFieldProps("company_code")} autoComplete="off" />
                            </div>

                            <div className="col-sm-3 col-12 form-group">
                                <label>Loại văn thư</label>
                                {detail && (detail.user_id != user.data.id && detail.approve_id != user.data.id)
                                    ?
                                    <select className="form-select height32" {...formik.getFieldProps("type")} disabled={true}>
                                        <option value="">Chọn loại văn thư</option>
                                        {vanThuParam && vanThuParam.map((item, i) => {
                                            return <option value={item.id} key={i}>{item.title}</option>
                                        })}
                                    </select>
                                    :
                                    <>
                                        <select className="form-select height32" {...formik.getFieldProps("type")} disabled={detail && user.data.id == detail.user_id && (detail.status == 1 || detail.status == 3 || detail.status == 4 || detail.status == 2) ? true : false}>
                                            <option value="">Chọn loại văn thư</option>
                                            {vanThuParam && vanThuParam.map((item, i) => {
                                                return <option value={item.id} key={i}>{item.title}</option>
                                            })}
                                        </select>

                                        <AlertErrorForm formik={formik} name="type"></AlertErrorForm>
                                    </>
                                }
                            </div>
                            {formik.values['type'] && formik.values['type'] == 55 ?
                                <div className="col-12 col-sm-3 form-group">
                                    <label>Văn thư gốc</label>
                                    <SearchBoxModal apiname={"vanthu"} alldata={'?status=4&parent_id=0'} placeholder="Chọn văn thư" colvalue={'id'} colname={'title'} defaultValue={formik.values['parent_id']} name='parent_id' disabled={detail && user.data.id == detail.user_id && (detail.status == 1 || detail.status == 3 || detail.status == 4 || detail.status == 2) ? true : false}
                                        onChange={async (e) => {
                                            await formik.setFieldValue('parent_id', e)
                                            setParentId(e)
                                        }}></SearchBoxModal>
                                </div>
                                :
                                ''
                            }
                            {formik.values['approve_id'] &&
                                <div className="col-12 col-sm-3 form-group">
                                    <label>Người cấp số </label>
                                    <SearchBoxModal apiname={"users"} placeholder="Người cấp số" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['approve_id']} name='approve_id' disabled={true}
                                        onChange={async (e) => {
                                            await formik.setFieldValue('approve_id', e)
                                        }}></SearchBoxModal>
                                </div>
                            }

                            <div className="col-sm-3 col-12 form-group ">
                                <label>Người ký</label>
                                <input type="text" placeholder="Ví dụ: TGĐ, HĐQT,..." className="form-control height32" {...formik.getFieldProps("ndvt")} autoComplete="off" disabled={detail && user.data.id == detail.user_id && (detail.status == 1 || detail.status == 3 || detail.status == 4 || detail.status == 2) ? true : false} />
                            </div>
                            <div className="col-sm-3 col-12 form-group ">
                                <label>Ngày ký</label>
                                <DateTimePicker onChange={(value) => { handleDate('ngayky', value) }} value={dateSelect.ngayky ? new Date(dateSelect.ngayky) : null} format="dd-MM-y" className="form-control height32 air-datepicker" disabled={detail && user.data.id == detail.user_id && (detail.status == 1 || detail.status == 3 || detail.status == 4 || detail.status == 2) ? true : false}></DateTimePicker>
                            </div>
                            <div className="col-sm-3 col-12 form-group">
                                <label>Trạng thái văn thư</label>
                                {detail && (detail.user_id != user.data.id && detail.approve_id != user.data.id)
                                    ? <>
                                        <select className="form-select height32" {...formik.getFieldProps("type_vt")} disabled={true}>
                                            <option value="">Chọn loại trạng thái văn thư</option>
                                            <option value="public">Công khai</option>
                                            <option value="private">Riêng tư</option>
                                            <option value="security">Bảo mật</option>
                                        </select>
                                    </>
                                    :
                                    <>
                                        <select className="form-select height32" {...formik.getFieldProps("type_vt")} disabled={detail && user.data.id == detail.user_id && (detail.status == 1 || detail.status == 3 || detail.status == 4 || detail.status == 2) ? true : false}>
                                            <option value="">Chọn loại trạng thái văn thư</option>
                                            <option value="public">Công khai</option>
                                            <option value="private">Riêng tư</option>
                                            <option value="security">Bảo mật</option>
                                        </select>
                                        <AlertErrorForm formik={formik} name="type_vt"></AlertErrorForm>
                                    </>
                                }
                            </div>
                            {(detail && detail.code) ? <div className="col-12 col-sm-12 form-group">
                                <label>Số văn thư</label>
                                <h4>{detail.code}</h4>
                            </div>
                                :
                                (formik && formik.values['code'] ?
                                    <div className="col-12 col-sm-12 form-group">
                                        <label>Số văn thư</label>
                                        <input type="text" placeholder="" className="form-control height32 mb-2" {...formik.getFieldProps("code")} autoComplete="off" />
                                    </div>
                                    :
                                    '')
                            }

                            {id > 0 && detail && detail.status > 1 ?
                                <div className="col-12 form-group">
                                    {detail.user_id == user.data.id || detail.approve_id == user.data.id || detail.file_url ? <label>File scan đính kèm</label> : ''}
                                    {detail.status != 4 && <input type="hidden" placeholder="" className="form-control height32 mb-2" {...formik.getFieldProps("file_url")} autoComplete="off" disabled />}
                                    {detail.hide_with_approve && detail.approve_id == user.data.id ?
                                        <div>
                                            Bạn bị từ chối xem bản scan lý do: {detail.resion_hide}
                                        </div>
                                        :
                                        <div className="row">
                                            {formik.values['file_url'] && formik.values['file_url'].length > 0 &&
                                                formik.values['file_url'].map((itemFile, key) => {
                                                    return <div className="col-12 col-sm-3 col-lg-2 mb-3" key={key}>
                                                        <div className="w-100 wrapper border rounded">
                                                            <a href={itemFile} target="_blank">
                                                                <img src={file_icon} alt='' className="w-100" />
                                                            </a>
                                                            <p className="w-100 text-center">{itemFile.length > 25 ? '...' + itemFile.slice(-25) : itemFile}</p>
                                                            {detail.status != 4 && <button type="button" className="btn btn-sm btn-danger w-100" onClick={() => {
                                                                var list = [...formik.values['file_url']]
                                                                const index = list.indexOf(itemFile);
                                                                if (index > -1) { // only splice array when item is found
                                                                    list.splice(index, 1); // 2nd parameter means remove one item only
                                                                }
                                                                formik.setFieldValue('file_url', list)
                                                            }}>Xóa</button>}
                                                        </div>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    }
                                    {(detail.status == 2 || detail.status == 7) && (detail.user_id == user.data.id) && formik ?
                                        <>
                                            <UploadButton defaultValue="" button_title="Tải lên" onSellect={(val) => { formik.setFieldValue('file_url', val) }} multiple={true} onlytype=".pdf" filename={detail.code_file + '.pdf'} type={'vanthu'} />
                                            <div className="col-12 col-sm-4 form-group mt-2">
                                                <label>Quản lý văn thư có quyền xem file scan ?</label>
                                                <select className="form-select height32" {...formik.getFieldProps("hide_with_approve")}>
                                                    <option value="">Có quyền xem</option>
                                                    <option value="1">Không có quyền xem</option>
                                                </select>
                                            </div>
                                            {formik && formik.values['hide_with_approve'] && formik.values['hide_with_approve'] == 1 ?
                                                <div className="col-12 col-sm-4 form-group mt-2">
                                                    <label>Lý do không cho xem</label>
                                                    <textarea className="form-control" {...formik.getFieldProps("resion_hide")}></textarea>
                                                </div>
                                                :
                                                ""}
                                        </>
                                        : ''
                                    }
                                    {detail.user_id == user.data.id && detail.status == 4 ?
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-12 form-group">
                                                    <h4>Người tham gia</h4>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-sm-4 form-group">
                                                            <label>Chọn nhân sự</label>
                                                            <SearchBoxModal apiname={"users"} alldata={"?userall=1"} placeholder="Người tham gia" colvalue={'id'} colname={'fullname'}
                                                                onChange={async (e) => {
                                                                    await setMember(e)
                                                                }}></SearchBoxModal>
                                                        </div>
                                                        <div className="col-12 col-sm-4 form-group">
                                                            <label>Chọn thời hạn</label>
                                                            <DateTimePicker onChange={(value) => { handleDate('dateend', value) }} value={dateSelect.dateend ? new Date(dateSelect.dateend) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                                        </div>
                                                        <div className="col-12 col-sm-4">
                                                            <label>#</label>
                                                            <button className="btn btn-sm btn-info text-white" type="button" onClick={() => addMember()}>Thêm</button>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        {listMember ? listMember.map((item, key) => {
                                                            return (<div className=" mt-2 border rounded p-1 w-100 d-flex justify-content-between align-items-center" key={key}>
                                                                <div className="group-name">
                                                                    <small>{item.label}</small>
                                                                    {item.dateend ? <small> - ngày hết hạn <Moment format="DD/MM/YYYY">{item.dateend}</Moment></small> : ''}
                                                                </div>
                                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteMember(item.id)}>
                                                                    Xóa
                                                                </button>
                                                            </div>)
                                                        }) : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            {detail.yeucau && detail.yeucau.length > 0 ?
                                                <div className="row mt-3">
                                                    <h4 className="w-100">Yêu cầu tham gia</h4>
                                                    {detail.yeucau.map((it, k) => (
                                                        <div className="col-12 mt-2" key={k}>
                                                            <div className="w-100 pl-2 d-flex justify-content-between align-items-center border rounded">
                                                                <div>{it.fullname}</div>
                                                                <div>
                                                                    <button type="button" className="btn btn-sm btn-info" onClick={() => { setShow(true); setIdSelect(it.id) }}>
                                                                        <span className="far fa-check-square"></span> Phê duyệt
                                                                    </button>
                                                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => tlYeucau(it.id, 2)}>
                                                                        <span className="far fa-times-circle"></span> Không duyệt
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                : ''}
                                        </>
                                        : ''}
                                </div>
                                : ''
                            }

                            <div className="col-12"></div>
                            {id > 0 && detail ?
                                <>{detail.approve_id == user.data.id ?
                                    <>
                                        {detail.status == 1 && detail.approve_id == user.data.id &&
                                            <div className="w-100 d-flex justify-content-center form-group mt-5">
                                                <div className="col-12 col-sm-6">
                                                    <label className="w-100 text-center">Thao tác</label>
                                                    <div className="w-100 d-flex justify-content-center">
                                                        {detail.code ?
                                                            ''
                                                            :
                                                            <input type="text" placeholder="Nhập số văn thư" className="form-control height32" autoComplete="off" {...formik.getFieldProps("number")} />
                                                        }
                                                        <button type="button" className="btn btn-sm btn-success"
                                                            onClick={() => {
                                                                formik.setFieldValue('status', 2)
                                                                handleClick()
                                                            }}>
                                                            Chấp thuận
                                                        </button>
                                                    </div>
                                                    <div className="w-100 d-flex justify-content-center mt-3">
                                                        <input type="text" placeholder="Nhập lý do từ chối" className="form-control height32" autoComplete="off" {...formik.getFieldProps("resion")} />
                                                        <button type="button" className="btn btn-sm btn-danger"
                                                            onClick={() => {
                                                                formik.setFieldValue('status', 6)
                                                                handleClick()

                                                            }}>
                                                            Từ chối duyệt
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 3 && detail.approve_id == user.data.id &&
                                            <div className="col-12 form-group mt-5">
                                                <label className="w-100 text-center">Thao tác</label>
                                                <div className="w-100 d-flex justify-content-center">
                                                    <div className="col-12 col-sm-6">
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <button type="button" className="w-100 btn btn-sm btn-success ml-2"
                                                                onClick={() => {
                                                                    formik.setFieldValue('status', 4)
                                                                    handleClick()
                                                                }}
                                                            >
                                                                <h4 className="text-white">Chấp thuận</h4>
                                                            </button>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-center mt-3">
                                                            <button type="button" className="btn btn-sm btn-danger ml-2"
                                                                onClick={() => {
                                                                    formik.setFieldValue('status', 7)
                                                                    handleClick()
                                                                }}
                                                            >Từ chối</button>
                                                            <input type="text" placeholder="Nhập lý do từ chối" className="form-control height32" autoComplete="off" {...formik.getFieldProps("resion")} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {detail.status == 5 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.checker_name} </b>
                                                    <span className="ml-1 mr-2 text-danger">đã từ chối duyệt.</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                        {detail.status == 6 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.approve_name} </b>
                                                    <span className="ml-1 mr-2 text-danger">đã từ chối duyệt.</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                        {detail.status == 4 && detail.type_vt == 'private' && !detail.ismember ?
                                            <div className="col-12 d-flex justify-content-center align-items-center mt-3">
                                                <button type="button" className="btn btn-sm btn-warning" onClick={() => yeuCauTiepCan()}>
                                                    Yêu cầu tiếp cận
                                                </button>
                                            </div>
                                            : ''}
                                        {detail.status == 7 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.approve_name} </b>
                                                    <span className="ml-1 mr-2 text-danger"> đã từ chối duyệt file scan</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {detail.status == 1 &&
                                            <div className="col-12 form-group mt-5 ">
                                                <div className="w-100 text-center text-danger mt-2">
                                                    Vui lòng cung cấp bản cứng cho Phòng hành chính và đợi cấp số
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 2 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.approve_name} </b>
                                                    <span className="ml-1 mr-2">đã cấp số cho văn thư với mã số là: </span>
                                                    <h4>{detail.code}</h4>
                                                </div>
                                                <div className="w-100 text-center text-danger mt-2">
                                                    Vui lòng cung cấp bản scan và upload lên
                                                </div>
                                            </div>
                                        }
                                        {detail.status == 5 &&
                                            <div className="col-12 form-group mt-5">
                                                <div className="w-100 d-flex justify-content-center align-items-center">
                                                    <b>{detail.checker_name} </b>
                                                    <span className="ml-1 mr-2 text-danger">đã từ chối duyệt.</span>
                                                </div>
                                                <div className="w-100 text-center">Lý do: {detail.resion}</div>
                                            </div>
                                        }
                                    </>
                                }
                                </>
                                :
                                formik.values['approve_id'] == user.data.id ?
                                    <div className="col-12 form-group mt-5">
                                        <label>Cấp số văn thư</label>
                                        <input type="text" placeholder="Nhập số văn thư" className="form-control height32" autoComplete="off" {...formik.getFieldProps("number")} />
                                    </div>
                                    :
                                    ''
                            }

                            <div className="col-12 mt-3"></div>
                            <div className={"col-12 d-flex justify-content-center form-group mg-t-8 " + ((detail && detail.user_id == user.data.id) || id == 0 || id == null ? "" : 'd-none')}>
                                {id > 0 ?
                                    detail && detail.status != 4 ?
                                        detail && detail.status != 3 && detail.status != 1 ?
                                            <button type="submit" disabled={formik.isSubmitting} ref={submitRef} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
                                                {loading ? <Spinner /> : "Cập nhật"}
                                            </button>
                                            :
                                            <button type="submit" disabled={formik.isSubmitting} ref={submitRef} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark d-none">x</button>
                                        : ''

                                    :
                                    <button type="submit" disabled={formik.isSubmitting} ref={submitRef} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : 'Đăng ký'}</button>
                                }
                            </div>
                            {detail && detail.status == 4 ?
                                <div className="w-100 d-flex justify-content-center mt-3">
                                    <div className="col-12 col-sm-3 bg-success text-center text-white py-2">Văn thư đã được Chấp thuận</div>
                                </div>
                                :
                                ''}
                        </div>
                    </form>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)} animation={true} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Phê duyệt yêu cầu tiếp cận</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 form-group">
                        <label>Chọn thời hạn</label>
                        <DateTimePicker onChange={(value) => { handleDate('dateyc', value) }} value={dateSelect.dateyc ? new Date(dateSelect.dateyc) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <button type="button" className="btn btn-sm btn-info text-white" onClick={() => tlYeucau(idSelect, 1)}>
                            <span className="far fa-check-square"></span> Phê duyệt
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </AkkhorLayout>
    );
}
