import React from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import DashboardAdmin from "./DashboardAdmin";

export default function HomePage() {
  const { user } = useSelector((state) => state.auth);
  
  return (
    <AkkhorLayout idrole="dashboard" roleignone={user.data.typeaccount===2}>
        <DashboardAdmin />
    </AkkhorLayout>
  );
}
