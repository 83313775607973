
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";


export default function FormCa() {
    const { user } = useSelector((state) => state.auth);
    const  {id}  = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    
    const defaultdetail = {
        part_id:'',author_id:user.data.id,title:'', status:1,type:1
    }
    useEffect(
        function () {
            if(id>0)
            {
                let mounted = true;
                Axios.get(URL_LIST_ALL  + "calam/"+id,user.config)
                    .then( async (res) => {
                    if (res.data.status === "success") {
                        if (mounted){
                            setDetail(res.data.data);
                        } 
                    } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                    return () => (mounted = false);
            }
        },[user,id,refresh]
      );
    const formik = useFormik({
        initialValues:detail?detail:defaultdetail,
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
          title: Yup.string().trim().nullable().required('Nhập tên ca làm việc').max(150, "Độ dài không quá 150 ký tự"),
          part_id: Yup.string().trim().nullable().required('Vui lòng chọn phòng ban'),
          time_start: Yup.string().trim().nullable().required('Nhập thời gian bắt đầu'),
          time_end: Yup.string().trim().nullable().required('Nhập thời gian kết thúc'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if(id>0)
            {
                Axios.put(URL_LIST_ALL+'calam/'+id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật ca thành công',
                        buttons: [{ label: 'OK' }]
                    }); }else{
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }else{
                // console.log(values);
                Axios.post(URL_LIST_ALL+'calam', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn thêm mới ca thành công',
                        buttons: [{ label: 'OK' }]
                    }); }else{
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

  return (
    <AkkhorLayout idrole="list-ca-lam">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    <Link to="/list-ca-lam">Danh sách ca làm việc</Link>
                </li>
                <li>{id ? 'Sửa ca làm việc' : 'Thêm mới ca làm việc'}</li>
            </ul>
        </div>
      <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Ca làm việc</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-lg-6 col-12 form-group">
                        <label>Tên ca làm việc <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                    </div>
                    <div className="col-xs-12"></div>
                    <div className="col-lg-6 col-12 form-group">
                        <label>Phòng ban </label>
                        <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" all={true} colvalue={'id'} colname={'title'} defaultValue={formik.values['part_id']} name='part_id'
                            onChange={async (e) => {
                                await formik.setFieldValue('part_id', e)
                            }}></SearchBoxModal>
                        <AlertErrorForm formik={formik} name="part_id"></AlertErrorForm>
                    </div>
                    <div className="col-xs-12"></div>
                    <div className="col-lg-6 col-12 form-group">
                        <label>Phân loại</label>
                        <select className="form-select height32" {...formik.getFieldProps("type")}>
                            <option value="1">Ca sáng</option>
                            <option value="2">Ca chiều</option>
                            <option value="3">Ca tối</option>
                        </select>
                    </div>
                    <div className="col-xs-12"></div>
                    <div className="col-lg-3 col-12 form-group">
                        <label>Thời gian bắt đầu <span className="text-danger">*</span></label>
                        <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_start")} />
                        <AlertErrorForm formik={formik} name="time_start"></AlertErrorForm>
                    </div>
                    <div className="col-lg-3 col-12 form-group">
                        <label>Thời gian kết thúc <span className="text-danger">*</span></label>
                        <input type="time" placeholder="" className="form-control height32" {...formik.getFieldProps("time_end")} />
                        <AlertErrorForm formik={formik} name="time_end"></AlertErrorForm>
                    </div>

                    <div className="col-xs-12"></div>
                    <div className="col-lg-6 col-12 form-group">
                        <label>Trạng thái</label>
                        <select className="form-select height32" {...formik.getFieldProps("status")}>
                            <option value="0">Khoá</option>
                            <option value="1">Hoạt động</option>
                        </select>
                    </div>
                    
                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner />:<>Lưu lại</>}</button>
                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
