import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

export default function FormHopDong() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams(); const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [listParam, setListParam] = useState([])
    const [listMauParam, setListMauParam] = useState([])
    const [detailMau, setDetailMau] = useState()
    const [paramTitle, setParamTitle] = useState('')
    const [paramValue, setParamValue] = useState('')
    const [dateSelect, setDateSelect] = useState({ datestart: null, dateend: null, datecreated: null })
    const defaultdetail = {
        title: '',
        type_id: '',
        luong: '',
        params: '',
        user_code: '',
        author_code: user.data.code,
        status: 1,
    }

    const listOfColumns = {
        fullname: 'Họ tên',
        code: 'Mã nhân viên',
        sex: 'Giới tính',
        companyname: 'Tên công ty',
        partname: 'Tên phòng ban',
        chucvu: 'Chức vụ',
        email: 'Email',
        mobile: 'Số điện thoại',
        cccd: 'Số CCCD/CMND/HC',
        birthday: 'Ngày sinh',
        cccd_date: 'Ngày cấp CCCD/CMND/HC',
        cccd_address: 'Nơi cấp CCCD/CMND/HC',
        address_tc: 'Địa chỉ thường chú',
        address: 'Địa chỉ hiện tại',
        bank_id: 'STK ngân hàng',
        bank_address: 'Chi nhánh ngân hàng',
        bank_city: 'Tỉnh/thành ngân hàng',
        bhxh_id: 'Mã bảo hiểm xã hội',
        mst_id: 'Mã số thuế',
        datejoining: 'Ngày gia nhập',
        datehdtv_start: 'Ngày bắt đầu HĐ thử việc',
        datehdtv_end: 'Ngày kết thúc HĐ thử việc',
        datehdld_start_1: 'Ngày bắt đầu HĐLĐ lần 1',
        datehdld_end_1: 'Ngày kết thúc HĐLĐ lần 1',
        datehdld_start_2: 'Ngày bắt đầu HĐLĐ lần 2',
        datehdld_end_2: 'Ngày kết thúc HĐLĐ lần 2',
        datehdld_start_3: 'Ngày bắt đầu HĐLĐ lần 3',
        datehdld_end_3: 'Ngày kết thúc HĐLĐ lần 3',
        hd_status: 'Tình trạng HĐ',
        qualification: 'Trình độ chuyên môn',
        majors: 'Chuyên ngành đào tạo',
        highschool: 'Trường đào tạo',
    }

    useEffect(function () {
        if (id > 0) {
            Axios.get(URL_LIST_ALL + "hopdong/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        let detail = res.data.data
                        setDetail(detail);
                        loadMauHD(detail.type_id)
                        setDateSelect({ datestart: detail.datestart, dateend: detail.dateend, datecreated: detail.datecreated })
                        setListParam(JSON.parse(detail.params))
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        }
    }, [user, id]);


    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Vui lòng nhập tên văn bản hợp đồng').max(200, "Độ dài không quá 200 ký tự"),
            user_code: Yup.string().trim().nullable().required('Vui lòng chọn đối tượng'),
            type_id: Yup.string().trim().nullable().required('Vui lòng chọn mẫu hợp đồng'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            values.status = 0
            setLoading(true)
            values.params = JSON.stringify(listParam)
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'hopdong/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật mẫu hợp đồng thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/edit-hop-dong/" + id
                                },
                            }
                            ]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'hopdong', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới mẫu hợp đồng thành công',
                            buttons: [{
                                label: 'OK', onClick: () => {
                                    window.location.href = "/edit-hop-dong/" + res.data.id
                                },
                            }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const addParams = () => {
        if (paramTitle && paramValue && paramTitle !== '' && paramValue !== '') {
            let newList = listParam.slice()
            newList.push({ key: paramTitle, value: paramValue })
            setListParam(newList)
            setParamTitle('')
            setParamValue('')
        }
    }
    const deleteParam = (key) => {
        let newList = listParam.slice()
        newList.splice(key, 1)
        setListParam(newList)
    }

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    const loadMauHD = (typeid) => {
        Axios.get(URL_LIST_ALL + "hopdong_type/" + typeid, user.config).then(async (res) => {
            if (res.data.status === "success") {
                setListMauParam(JSON.parse(res.data.data.params))
                setDetailMau(res.data.data)
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
    }

    const editParams = (i, type, value) => {
        let cloneParams = listParam.slice()
        cloneParams[i][type] = value
        setListParam(cloneParams)
    }

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/hop-dong">Danh sách Văn bản, Hợp đồng</Link>
                    </li>
                    <li>{id ? 'Cập nhập Văn bản, Hợp đồng' : 'Thêm mới Văn bản, Hợp đồng'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Quản lý Văn bản, Hợp đồng</h3>
                        </div>
                    </div>

                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-4 col-12 form-group">
                                <label>Tên văn bản - hợp đồng <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            <div className="col-sm-4 col-12 form-group">
                                <label>Đối tượng sử dụng </label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn nhân sự" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['user_code']} name='user_code'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('user_code', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="user_code"></AlertErrorForm>
                            </div>

                            <div className="col-12"></div>

                            <div className="col-sm-4 col-12 form-group">
                                <label>Chọn mẫu văn bản - hợp đồng </label>
                                <SearchBoxModal apiname={"hopdong_type"} placeholder="Chọn văn bản/hợp đồng" colvalue={'id'} colname={'title'} defaultValue={formik.values['type_id']} name='type_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('type_id', e)
                                        loadMauHD(e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="type_id"></AlertErrorForm>
                            </div>
                            {detailMau && detailMau.urlfile &&
                                <div className="col-sm-4 col-12 form-group">
                                    <label>Xem mẫu văn bản - hợp đồng </label>
                                    <a className="btn btn-sm btn-success" target="_blank" href={'https://view.officeapps.live.com/op/embed.aspx?src=https://apihr.bhsgroup.vn/'+detailMau.urlfile}>Xem file</a>
                                </div>
                            }

                            <div className="col-12"></div>
                            <div className="col-3 form-group">
                                <label>Mức lương: </label>
                                <input type="text" placeholder="Mức lương" className="form-control height32" {...formik.getFieldProps("luong")} autoComplete="off" />
                            </div>
                            <div className="col-3 form-group">
                                <label>Ngày bắt đầu: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datestart', value) }} value={dateSelect.datestart ? new Date(dateSelect.datestart) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-3 form-group">
                                <label>Ngày kết thúc: </label>
                                <DateTimePicker onChange={(value) => { handleDate('dateend', value) }} value={dateSelect.dateend ? new Date(dateSelect.dateend) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-3 form-group">
                                <label>Ngày tạo: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datecreated', value) }} value={dateSelect.datecreated ? new Date(dateSelect.datecreated) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-sm-12 col-12 form-group">
                                <label>Tham số trong văn bản Mẫu</label>
                                <div className="row">
                                    {listMauParam.map((k, i) => {
                                        return (<div className="col-3 mb-3" key={i}>
                                            <small>{listOfColumns[k.value]}</small><br />
                                            <div className="w-100 d-flex">
                                                <input type='text' className="form-control height32" value={k.title} readOnly />
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div className="col-12 mt-3" ><hr /></div>
                            <div className="col-sm-12 col-12 form-group">
                                <label>Chọn các tham số thay thế chưa có</label>
                                <div className="row">
                                    <div className="col-4 form-group">
                                        <input type="text" placeholder="Thông số" className="form-control " onChange={(e) => setParamTitle(e.target.value)} autoComplete="off" value={paramTitle} />
                                    </div>
                                    <div className="col-4 form-group">
                                        <textarea className="form-control" placeholder="Dữ liệu" onChange={(e) => setParamValue(e.target.value)} value={paramValue} ></textarea>
                                    </div>
                                    <div className="col-4 form-group">
                                        <button type="button" className="btn btn-sm btn-info height32" onClick={() => addParams()}>Thêm</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-12 form-group">
                                {listParam && listParam.length > 0 && <>
                                    <label>Thông số thay thế trong văn bản</label>
                                    <div className="row">
                                        {listParam.map((k, i) => {
                                            return (<div className="col-12 mb-3" key={i}>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <input type='text' className="form-control height32" value={k.key} onChange={(e) => editParams(i, 'key', e.target.value)} />
                                                    </div>
                                                    <div className="col-5">
                                                        <input type='text' className="form-control height32" value={k.value} onChange={(e) => editParams(i, 'value', e.target.value)} />
                                                    </div>
                                                    <div className="col-2">
                                                        <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteParam(i)}>Xóa</button>
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </>
                                }
                            </div>
                            {detail && detail.file_url &&
                                <div className="col-12 form-group mg-t-8">
                                    <a className="btn btn-sm btn-success" target="_blank" href={'https://apihr.bhsgroup.vn/' + detail.file_url}>Xem file</a>
                                </div>
                            }

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </AkkhorLayout>
    );
}
