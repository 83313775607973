
import React, { useState } from "react";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";


export default function ListTimeSettings() {
  const [params, setParam] = useState();
  const [keyword, setKeyword] = useState(null)
  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if(keyword) _param = '&key='+keyword 
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "company", label: "Công ty" },
    { name: "time_start", label: "Thời gian chấm công" },
    { name: "type", label: "Loại" },
    { name: "comment", label: "Nội dung" },
    { name: "datecreate", label: "Ngày tạo" },
  ];

  return (<AkkhorLayout idrole="list-users_onday_settings">
    <SRList name="users_onday_settings" idrole="list-users_onday_settings" params={params} title="Cài đặt thời gian chấm công" defaultLimit={50} serverSide={true} columns={columns} >
        <div className="card mb-3">
            <div className="card-body ">
            <div className="d-flex pb-2">
                <div className="mr-auto">
                <h3>Tìm kiếm</h3>
                </div>
            </div>
            <form className="new-added-form" method="get" >
                <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
                    <label>Từ khóa </label>
                    <input type="text" placeholder="Tìm theo từ khóa" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
                
                <div className="col-12 ">
                    <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
                </div>
                </div>
            </form>
            </div>
        </div>
    </SRList>
  </AkkhorLayout>);
}
