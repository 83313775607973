import Axios from "axios";
import React, {  useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import { confirmAlert } from "react-confirm-alert";
import AlertErrorForm from "./AlertErrorForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkRole } from "../model";

export default function ResetPass(props) {
    const { id } = props;
    const { user } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);

    const formik = useFormik({
        initialValues: {password:'',repassword:''},
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
            password: Yup.string().required('Nhập mật khẩu').min(6,"Độ dài mật khẩu từ 6 ký tự trở lên").max(150, "Độ dài không quá 150 ký tự"),
            repassword: Yup.string().required('Nhập xác nhận mật khẩu').oneOf([Yup.ref('password'), null], 'Xác nhận mật khẩu không chính xác'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            Axios.put(URL_LIST_ALL+'reset/'+id,values, user.config).then((res)=>{
                setSubmitting(false)
                setShow(false)
                if(res.data.status==='success'){
                  confirmAlert({ title: 'Thành công',message: 'Đặt lại mật khẩu thành công',buttons: [{ label: 'OK' }]}); 
                }else{
                    confirmAlert({ title: 'Lỗi',message: res.data.message,buttons: [{ label: 'OK' }]}); 
                }
              }).catch((err)=>{
                setSubmitting(false)
                  setShow(false)
                  console.log(err)
              })
        }
    });
    const allowReset = checkRole(user,'list-users','reset');
    return (
        <>
        {allowReset?<>
        <button className="btn btn-info btn-sm" onClick={()=>setShow(true)}><i className="fas fa-lock"></i> Đặt lại</button>
        </>:<>
        <button className="btn btn-secondary btn-sm" disabled ><i className="fas fa-lock"></i> Đặt lại</button>
        </>}
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Đặt lại mật khẩu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <>
                    <div className="col-12 form-group">
                        <label>Mật khẩu <span className="text-danger">*</span></label>
                        <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("password")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="password"></AlertErrorForm>
                    </div>
                    <div className="col-12 form-group">
                        <label>Xác nhận mật khẩu<span className="text-danger">*</span></label>
                        <input type="password" placeholder="" className="form-control height32" {...formik.getFieldProps("repassword")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="repassword"></AlertErrorForm>
                    </div>
                    </>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary btn-sm" onClick={()=>setShow(false)}>Đóng</button>
                <button type="submit" className="btn btn-success btn-sm">Lưu</button>
            </Modal.Footer>
            </form>
        </Modal>
        </>
    );
}
