import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function ListChucVu() {
  const [params, setParam] = useState('');
  const [title, setTitle] = useState();
  const [filterShow, setFilterShow] = useState(false)
  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên chức vụ", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "part", label: "Phòng ban" },
    { name: "company", label: "Công ty" },
    { name: "datecreate", label: "Ngày tạo" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (title && title !== '') _param += "&title=" + title
    setParam(_param);
  };


  return (<AkkhorLayout idrole="list-chucvu">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>

        <li>Danh sách chức vụ</li>
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul" onClick={() => setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>

        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
              <label>Tên chức danh</label>
              <input type="text" placeholder="Tên chức danh" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control height32" autoComplete="off" />
            </div>
            
            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="chucvu" idrole="list-chucvu" params={params} title="Chức Vụ" defaultLimit={50} serverSide={true} columns={columns}>

    </SRList>
  </AkkhorLayout>);
}
