
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

export default function DanhMucTin() {
  const [params, setParam] = useState();
  const [filterShow, setFilterShow] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [dateCheck, setDateCheck] = useState(null)
  const [dateSelect, setDateSelect] = useState({datecheck:null})

  const [status, setStatus] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (status && status !== '') _param += "&status=" + status
    if (dateCheck && dateCheck !== '') _param += "&dateCheck=" + dateCheck
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  const handleDate = (name, value) => {
    setDateCheck( moment(value).format('YYYY-MM-DD'))
    let newDate = Object.assign({}, dateSelect)
    newDate[name] = value
    setDateSelect(newDate)
  };
  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tiêu đề" },
    { name: "alias", label: "Link rút gọn" },
    { name: "parent", label: "Danh mục cha" },
    { name: "author", label: "Người tạo" },
    { name: "datecreate", label: "Ngày tạo" },
    { name: "datemodified", label: "Ngày sửa" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
        <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              Danh mục
            </li>
        </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={()=>setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-md-6 col-12 form-group">
              <label>Khoảng thời gian tạo</label>
              <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: new Date(), }}
                onCallback={handleCallback}
              >
                <div className='d-flex align-items-center'>
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setStartDate(e)}
                    value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                  />
                  <span className='fas fa-arrow-right mx-4' />
                  <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setEndDate(e)}
                    value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                  />
                </div>
              </DateRangePicker>
            </div>
            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label>Ngày tháng cụ thể</label>
              <DateTimePicker onChange={(value) => { handleDate('datecheck', value) }} value={dateSelect.datecheck ? new Date(dateSelect.datecheck) : null} maxDate={new Date()} format="y-MM-dd" className="form-control height32 air-datepicker"></DateTimePicker>
            </div>
            

            <div className="col-lg-3 col-12 form-group">
              <label>Trạng thái</label>
              <select className="form-select height32" onChange={(e)=>setStatus(e.target.value)}>
                <option value="0">Ngưng kích hoạt</option>
                <option value="1">Kích hoạt</option>
              </select>
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="news_category" linkaction="danh-muc-tin" idrole="danh-muc-tin" params={params} title="Danh mục" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
