import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { useFormik } from "formik";
import Spinner from "../component/Spinner";
import { URL_LIST_ALL } from "../configs/api";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";
import * as Yup from "yup";

export default function XuatGiaiTrinhCong(props) {
    const { user } = useSelector((state) => state.auth);
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])
    const [linkDown, setLinkDown] = useState(null)

    const formik = useFormik({
        initialValues: { month: new Date().getMonth, year: new Date().getFullYear },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            month: Yup.string().trim().nullable().required('Vui lòng chọn tháng'),
            year: Yup.string().trim().nullable().required('Vui lòng chọn năm')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            let _url = URL_LIST_ALL + 'giaitrinh?export=true&limit=10000000'
            if (values && values.part_id) _url = _url + '&part_id=' + values.part_id
            if (values && values.company_id) _url = _url + '&company_id=' + values.company_id
            if (values && values.month && values.year) _url = _url + '&time=' + values.year + '-' + values.month

            Axios.get(_url, user.config).then(async (res) => {
                console.log(res)
                setSubmitting(false);
                if (res.data.status === "success") {
                    resetForm();
                    window.location.href = res.data.data
                    setLinkDown(res.data.data)
                } else {
                    confirmAlert({
                        title: 'Lỗi',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch((err) => console.log(err));
        },
    });

    useEffect(function () {
        let _month = []
        for (let m = 1; m <= 12; m++) {
            _month.push(m)
        }
        setMonth(_month)

        let _year = []
        let maxYear = new Date().getFullYear()
        for (let y = 2021; y <= maxYear; y++) {
            _year.push(y)
        }
        setYear(_year)
    }, []);

    return (
        <AkkhorLayout idrole="list-users" >
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/list-giaitrinh">Danh sách giải trình công</Link>
                    </li>
                    <li>Xuất giải trình công</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Xuất giải trình công</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 mg-t-8">
                                <div className="col-12 col-sm-4 form-group">
                                    <div className="row">
                                        <div className="col-lg-6 col-12 form-group">
                                            <label>Chọn tháng</label>
                                            <select className="form-select height32" {...formik.getFieldProps("month")}>
                                                {month && month.map((item, i) => {
                                                    return <option key={i} value={item < 10 ? '0' + item.toString() : item}>Tháng {item}</option>
                                                })}
                                            </select>
                                            <AlertErrorForm formik={formik} name="month"></AlertErrorForm>
                                        </div>
                                        <div className="col-lg-6 col-12 form-group">
                                            <label>Chọn năm</label>
                                            <select className="form-select height32" {...formik.getFieldProps("year")}>
                                                {year && year.map((item, i) => {
                                                    return <option key={i} value={item < 10 ? '0' + item.toString() : item}>{item}</option>
                                                })}
                                            </select>
                                            <AlertErrorForm formik={formik} name="year"></AlertErrorForm>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Xuất theo Công ty</label>
                                    <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" all={true} colvalue={'id'} colname={'title'} formik={formik} name='company_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('company_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="company_id"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Xuất theo Phòng ban</label>
                                    <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" all={true} colvalue={'id'} colname={'title'} formik={formik} name='part_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('part_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="part_id"></AlertErrorForm>
                                </div>
                                <div className="col-12"></div>

                                <div className="alert alert-primary" role="alert">
                                    <div className="mt-3">
                                        <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mr-2">{formik.isSubmitting ? <Spinner /> : <>Xuất File</>}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>

                    {linkDown &&
                        <div className="w-100 text-center">
                            <p>Đã load xong vùi lòng chọn tải về</p>
                            <a className="btn btn-danger" href={linkDown}>Tải về</a>
                        </div>
                    }
                </div>
            </div>
        </AkkhorLayout>
    );
}
