import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { actions } from "../redux/authRedux";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";

export default function Loading(props) {
  const {disabledbutton, expire,color,buttonhide} = props;
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false);


  useEffect( () =>{
   if(expire===true)
   {
      Axios.get(URL_LIST_ALL + "refresh", { headers: { 'Authorization': 'Bearer ' + user.refresh_token }}).then((res) => {
      if(res.data.status === 'success') {
        let user = res.data;
        user.config={ headers: { 'Authorization': 'Bearer ' + res.data.access_token} }
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token
        dispatch(actions.login(user));
      }else{
        dispatch(actions.login(null));
      }
    }).catch((err) => console.log(err));
   }
  },[expire]);// eslint-disable-line

  useEffect(function () {
    let mounted = true;
    setTimeout(() => {
      if(mounted)setShowButton(true);
    }, 15000);
    return () => (mounted = false);
  },[]);
  
  //Hàm xử lý đăng xuất
  const dologout = () => {
    dispatch(actions.login(null));
  };

  return (
    <div
        className="position-fixed d-flex fixed-top fixed-bottom justify-content-center align-items-center"
        style={{ background: "rgba(0,0,0,0.6)" }}
    >
      <div>
        <CircularProgress size={100} value={100} color={color} />
        {!buttonhide && showButton && !disabledbutton && <p className="text-center mt-5"><button className="btn btn-primary" onClick={dologout} >Đăng nhập lại</button></p>}
        </div>
    </div>
  );
}
