
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

export default function FormPhanCa() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [listDayOfWeek, setListDayOfWeek] = useState({ T2: true, T3: true, T4: true, T5: true, T6: true, T7: true, CN: true });
    const [dateSelect, setDateSelect] = useState({ datestart: null, dateend: null });
    const defaultdetail = {
        doituong: '', doituong_id: '', idca: null, dayofweek: {}, datestart: '', dateend: '', author_id: user.data.id, status: 1
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "calamviec/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let data = res.data.data
                                if(data.doituong === 'users') data.user_id = data.doituong_id
                                if(data.doituong === 'parts') data.part_id = data.doituong_id
                                if(data.doituong === 'company') data.company_id = data.doituong_id
                                setListDayOfWeek(JSON.parse(data.dayofweek))
                                setDetail(data);
                                setDateSelect({datestart:data.datestart,dateend:data.dateend})
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            doituong_id: Yup.string().trim().nullable().required('Vui lòng chọn đối tượng')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            values.dayofweek = listDayOfWeek
            console.log(values);
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'calamviec/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật ca thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                // console.log(values);
                Axios.post(URL_LIST_ALL + 'calamviec', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới ca thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    return (
        <AkkhorLayout idrole="list-ca-lam">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/phan-ca-lam">Danh sách ca làm việc</Link>
                    </li>
                    <li>{id ? 'Sửa ca làm việc' : 'Thêm mới ca làm việc'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Ca làm việc</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-4 col-12 form-group">
                                <label>Đối tượng áp dụng <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("doituong")}>
                                    <option value="">Chọn đối tượng</option>
                                    <option value="users">Cá nhân</option>
                                    <option value="parts">Phòng ban</option>
                                    <option value="company">Công ty</option>
                                </select>
                            </div>
                
                            {formik.values['doituong'] === 'company' &&
                                <div className="col-lg-4 col-12 form-group">
                                    <label>Chọn Công ty </label>
                                    <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" all={true} colvalue={'id'} colname={'title'} defaultValue={formik.values['doituong_id']} name='company_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('doituong_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="doituong_id"></AlertErrorForm>
                                </div>
                            }
                            {formik.values['doituong'] === 'parts' &&
                                <div className="col-lg-4 col-12 form-group">
                                    <label>Chọn phòng ban </label>
                                    <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" all={true} colvalue={'id'} colname={'title'} defaultValue={formik.values['doituong_id']} name='part_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('doituong_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="doituong_id"></AlertErrorForm>
                                </div>
                            }

                            {formik.values['doituong'] === 'users' &&
                                <div className="col-lg-4 col-12 form-group">
                                    <label>Chọn nhân sự </label>
                                    <SearchBoxModal apiname={"users"} placeholder="Chọn nhân sự" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['doituong_id']} name='user_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('doituong_id', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="doituong_id"></AlertErrorForm>
                                </div>
                            }

                            <div className="col-xs-12"></div>
                            <div className="col-lg-8 col-12 form-group">
                                    <label>Chọn ca </label>
                                    <SearchBoxModal apiname={"quanlyca"} placeholder="Chọn ca làm việc" colvalue={'id'} colname={'title'} defaultValue={formik.values['idca']} name='idca'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('idca', e)
                                        }}></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="idca"></AlertErrorForm>
                                </div>
                            <div className="col-xs-12"></div>

                            <div className="col-lg-8 col-12 form-group">
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <strong>Áp dụng trong tuần</strong>
                                    </div>
                                    {Object.keys(listDayOfWeek).map((item, i) => (
                                        <div className="col form-check" key={i}>
                                            <input type="checkbox" name="dayofweek" value={item} checked={listDayOfWeek[item]} onChange={(e) => { setListDayOfWeek({ ...listDayOfWeek, [item]: e.target.checked }) }} className="" />
                                            <label className="form-check-label">
                                                {item}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-12 col-12 form-group">
                                <strong>Hiệu lực</strong>
                            </div>
                            <div className="col-sm-4 col-12 form-group">
                                <label>Từ ngày</label>
                                <DateTimePicker onChange={(value) => { handleDate('datestart', value) }} minDate={new Date()} value={dateSelect.datestart ? new Date(dateSelect.datestart) : ''} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="date_start"></AlertErrorForm>
                            </div>
                            <div className="col-sm-4 col-12 form-group">
                                <label>Đến ngày</label>
                                <DateTimePicker onChange={(value) => { handleDate('dateend', value) }} value={dateSelect.dateend ? new Date(dateSelect.dateend) : ''} format="dd-MM-y" className="form-control height32 air-datepicker"
                                    minDate={dateSelect.dateend ? new Date(dateSelect.dateend) : new Date()}></DateTimePicker>
                                <AlertErrorForm formik={formik} name="date_end"></AlertErrorForm>
                            </div>

                            <div className="col-xs-12"></div>
                            <div className="col-lg-4 col-12 form-group">
                                <label>Trạng thái</label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
